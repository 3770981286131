import { styled } from '@mui/material';
import { Typography } from '@elements';
var StyledTypography = styled(Typography)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.5px'
  };
  return styles;
});
export { StyledTypography };