import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { CLOSE_MODAL, CLOSING_MODAL_WITH_ANSWER, HIDE_MODAL, INIT_CLOSE_MODAL, INIT_MODAL_CLOSING_WITH_ANSWER, MODAL_INIT, SET_MODAL } from './modals.action-types';
export var actions = {
  modalInitClosing: function modalInitClosing(payload) {
    return {
      type: INIT_CLOSE_MODAL,
      payload: payload
    };
  },
  modalClose: function modalClose(payload) {
    return {
      type: CLOSE_MODAL,
      payload: payload
    };
  },
  modalInit: function modalInit(payload) {
    var _ref;

    return _ref = {
      type: MODAL_INIT,
      payload: payload
    }, _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(MODAL_INIT, "/").concat(payload.modalId, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, ERROR_ACTION, "".concat(MODAL_INIT, "/").concat(payload.modalId, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _ref;
  },
  setModal: function setModal(payload) {
    return {
      type: SET_MODAL,
      payload: payload
    };
  },
  modalInitClosingWithAnswer: function modalInitClosingWithAnswer(payload) {
    return {
      type: INIT_MODAL_CLOSING_WITH_ANSWER,
      payload: payload
    };
  },
  closeWithAnswer: function closeWithAnswer(payload) {
    return {
      type: CLOSING_MODAL_WITH_ANSWER,
      payload: payload
    };
  },
  hideModal: function hideModal(payload) {
    return {
      type: HIDE_MODAL,
      payload: payload
    };
  }
};