import { createSelector } from 'reselect';

var getGrids = function getGrids(state) {
  return state.grids;
};

export var getGridsSelector = createSelector(getGrids, function (grids) {
  return grids;
});
export var getGridSelector = function getGridSelector(gridId) {
  return createSelector(getGrids, function (grids) {
    return grids.find(function (grid) {
      return grid.gridId === gridId;
    });
  });
};