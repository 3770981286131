import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { alpha, Box, styled } from '@mui/material';
import { Button, Typography } from '@elements';
var StyledButton = styled(Button)(function (_ref) {
  var theme = _ref.theme,
      fullWidth = _ref.fullWidth;
  var styles = {
    '&&': {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.typography.pxToRem(12),
      backgroundColor: theme.palette.internationalOrange.main,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      color: theme.palette.white.main,
      textTransform: 'uppercase',
      '&:focus': {
        boxShadow: 'none'
      },
      '&:disabled': {
        backgroundColor: 'rgba(139, 139, 139, 0.08)',
        color: theme.palette.silverChalice.main,
        border: "transparent"
      }
    }
  };
  return styles;
});
var StyledTypography = styled(Typography)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.5px'
  };
  return styles;
});
var StyledTypographyLast = styled(Typography)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.5px'
  };
  return styles;
});
var StyledTitleContainer = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {};
  return styles;
});
var StyledBg = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    display: 'none'
  }, theme.breakpoints.down('sm'), {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '100vw',
    height: '100%',
    background: 'linear-gradient(84deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.10) 99.56%)',
    transform: 'translateX(-50%)'
  });

  return styles;
});
var RemoveButton = styled(Button)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    '&&': {
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center'
    }
  };
  return styles;
});
var BasketRow = styled(Box)(function (_ref7) {
  var _hover;

  var theme = _ref7.theme;
  var styles = {
    backgroundColor: alpha(theme.palette.white.main, 0.03),
    ':hover': (_hover = {
      backgroundColor: alpha(theme.palette.white.main, 0.09)
    }, _defineProperty(_hover, "".concat(StyledTypographyLast), {
      display: 'none'
    }), _defineProperty(_hover, "".concat(RemoveButton), {
      display: 'flex'
    }), _hover)
  };
  return styles;
});
export { BasketRow, RemoveButton, StyledBg, StyledButton, StyledTitleContainer, StyledTypography, StyledTypographyLast };