import { Box, Container, styled } from '@mui/material';
var StyledPrivateWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    minHeight: '100vh',
    maxWidth: '100%',
    display: 'flex'
  };
  return styles;
});
var StyledMain = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1',
    width: '100%'
  };
  return styles;
});
var StyledContainerWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column'
  };
  return styles;
});
var StyledContainer = styled(Container)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column'
  };
  return styles;
});
export { StyledContainer, StyledContainerWrapper, StyledMain, StyledPrivateWrapper };