import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { produce } from 'immer';
import { SocketConnectionState } from '@enums';
import { FETCH_SEND_SOCKET_EVENT_ERROR, FETCH_SEND_SOCKET_EVENT_SUCCESS, FETCH_SOCKET_CONNECT_FAILURE, FETCH_SOCKET_CONNECT_REQUEST, FETCH_SOCKET_CONNECT_SUCCESS, RECEIVE_SOCKET_EVENT } from './socket.action-types';
var initialState = {
  connected: false,
  state: SocketConnectionState.DISCONNECTED,
  receiveEvent: undefined,
  sendEvent: undefined
};
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_SOCKET_CONNECT_REQUEST:
      {
        return produce(state, function (draft) {
          draft.state = SocketConnectionState.CONNECTING;
          draft.connected = false;
        });
      }

    case FETCH_SOCKET_CONNECT_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.state = SocketConnectionState.CONNECTED;
          draft.connected = true;
        });
      }

    case FETCH_SOCKET_CONNECT_FAILURE:
      {
        return produce(state, function (draft) {
          draft.state = SocketConnectionState.DISCONNECTED;
          draft.connected = false;
        });
      }

    case RECEIVE_SOCKET_EVENT:
      {
        return produce(state, function (draft) {
          draft.receiveEvent = action.payload;
        });
      }

    case FETCH_SEND_SOCKET_EVENT_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.sendEvent = action.payload;
        });
      }

    case FETCH_SEND_SOCKET_EVENT_ERROR:
      {
        return produce(state, function (draft) {
          draft.sendEvent = undefined;
        });
      }

    default:
      {
        return _objectSpread({}, state);
      }
  }
});