import { formatISO, isValid, parseISO } from 'date-fns/esm';

var convertedDateToObject = function convertedDateToObject(date, representation) {
  if (!date || !isValid(date)) {
    return undefined;
  }

  var newDate = typeof date === 'string' ? parseISO(date) : date;
  return parseISO(formatISO(newDate, {
    representation: representation || 'date'
  }));
}; // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any


function setTimeout_(fn, delay) {
  var maxDelay = Math.pow(2, 31) - 1;

  if (delay > maxDelay) {
    // eslint-disable-next-line prefer-rest-params
    var args = arguments;
    args[1] -= maxDelay;
    return setTimeout(function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line prefer-spread
      setTimeout_.apply(undefined, args);
    }, maxDelay);
  } // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line prefer-rest-params,prefer-spread


  return setTimeout.apply(undefined, arguments);
}

export { convertedDateToObject, setTimeout_ };