export var Routes;

(function (Routes) {
  Routes["All"] = "*";
  Routes["Main"] = "/";
  Routes["Events"] = "/events";
  Routes["Sectors"] = "/events/:eventId";
  Routes["Sector"] = "/events/:eventId/:sectorId";
  Routes["Membership"] = "/membership";
  Routes["Service"] = "/service";
  Routes["Help"] = "/help";
  Routes["Shop"] = "/shop";
  Routes["Contacts"] = "/contacts";
  Routes["Basket"] = "/basket";
  Routes["Profile"] = "/profile";
  Routes["Order"] = "/order/:orderId";
  Routes["PaymentSuccess"] = "/payment/success/:orderId";
  Routes["PaymentError"] = "/payment/error/:orderId";
  Routes["Empty"] = "/empty";
  Routes["Error"] = "/error";
  Routes["NotFound"] = "/404";
})(Routes || (Routes = {}));