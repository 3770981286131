import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { produce } from 'immer';
import { CREATE_FORM_STATE, DELETE_FORM_STATE, UPDATE_FORM_STATE } from './forms.action-types';
var initialState = [];
var initialFormState = {
  dirty: false,
  dirtyFields: {},
  dirtyFieldsSinceLastSubmit: {},
  dirtySinceLastSubmit: false,
  errors: {},
  hasSubmitErrors: false,
  hasValidationErrors: false,
  invalid: false,
  modified: {},
  modifiedSinceLastSubmit: false,
  pristine: true,
  submitFailed: false,
  submitSucceeded: false,
  submitting: false,
  touched: {},
  valid: true,
  validating: false,
  values: {},
  visited: {}
};

var formsReducer = function formsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case CREATE_FORM_STATE:
      return produce(state, function (draft) {
        var idx = state.findIndex(function (form) {
          return form.formId === action.payload.formId;
        });

        if (idx < 0) {
          draft.push(_objectSpread({
            formId: action.payload.formId
          }, initialFormState));
        }
      });

    case UPDATE_FORM_STATE:
      return produce(state, function (draft) {
        var idx = state.findIndex(function (form) {
          return form.formId === action.payload.formId;
        });

        if (idx >= 0) {
          draft[idx] = action.payload;
        } else {
          draft.push(action.payload);
        }
      });

    case DELETE_FORM_STATE:
      return produce(state, function (draft) {
        var idx = state.findIndex(function (form) {
          return form.formId === action.payload.formId;
        });

        if (idx >= 0) {
          draft.splice(idx, 1);
        }
      });

    default:
      return state;
  }
};

export default formsReducer;