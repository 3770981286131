import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { alpha, Autocomplete, Box, FormControl, FormHelperText, Input, InputBase, inputBaseClasses, InputLabel, styled, TextField } from '@mui/material';
import { pxToRem } from '@themes';
var StyledFormControl = styled(FormControl)(function (_ref) {
  var fullWidth = _ref.fullWidth;
  var styles = {
    display: fullWidth && 'flex',
    maxWidth: '100%'
  };
  return styles;
});
var StyledInputBase = styled(InputBase)(function (_ref2) {
  var _styles;

  var theme = _ref2.theme,
      color = _ref2.color;
  var styles = (_styles = {
    border: "".concat(theme.typography.pxToRem(1), " solid ").concat(theme.palette.mirage.main),
    borderRadius: theme.typography.pxToRem(12),
    overflow: 'hidden',
    transition: theme.transitions.create(['border-color'])
  }, _defineProperty(_styles, "& .".concat(inputBaseClasses.input), {
    boxSizing: 'border-box',
    height: theme.typography.pxToRem(48),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 500,
    letterSpacing: '-0.003em',
    color: theme.palette.mirage.main,
    padding: theme.spacing(1.75, 2),
    position: 'relative'
  }), _defineProperty(_styles, '&: hover', {
    borderColor: theme.palette.mirage.main
  }), _defineProperty(_styles, "&.".concat(inputBaseClasses.focused), {
    borderColor: theme.palette.mirage.main,
    outline: "".concat(theme.typography.pxToRem(4), " solid ").concat(alpha(theme.palette.mirage.main, 0.2))
  }), _defineProperty(_styles, "&.".concat(inputBaseClasses.error), {
    borderColor: theme.palette.mirage.main,
    outline: "".concat(theme.typography.pxToRem(4), " solid ").concat(alpha(theme.palette.mirage.main, 0.2))
  }), _defineProperty(_styles, "&.".concat(inputBaseClasses.disabled), {
    color: theme.palette.mirage.main
  }), _defineProperty(_styles, '& > input::placeholder', {
    color: theme.palette.mirage.main,
    opacity: 1
  }), _styles);
  return styles;
});
var StyledInput = styled(Input)(function (_ref3) {
  var theme = _ref3.theme,
      color = _ref3.color;
  var styles = {
    '& .MuiSvgIcon-root': {
      width: pxToRem(10),
      height: pxToRem(10),
      svg: {
        width: pxToRem(10),
        height: pxToRem(10)
      }
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(0.5, 0),
      fontSize: pxToRem(12)
    }
  };
  return styles;
});
var StyledInputLabel = styled(InputLabel)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    position: 'relative',
    transform: 'unset',
    transformOrigin: 'unset',
    maxWidth: '100%',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 500,
    color: theme.palette.mirage.main,
    letterSpacing: '-0.0015em',
    display: 'block',
    whiteSpace: 'unset',
    paddingLeft: theme.spacing(0.5),
    '&.Mui-focused': {
      color: theme.palette.mirage.main
    },
    '&.Mui-error': {
      color: theme.palette.mirage.main
    }
  };
  return styles;
});
var StyleFormHelperWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    marginTop: theme.typography.pxToRem(4),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    zIndex: 1
  };
  return styles;
});
var StyledFormHelperErrorText = styled(FormHelperText)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    color: theme.palette.mirage.main,
    display: 'block',
    padding: theme.spacing(0, 2),
    margin: 'unset',
    maxWidth: '100%',
    '&.Mui-error': {
      color: theme.palette.mirage.main
    }
  };
  return styles;
});
var StyledFormHelperText = styled(FormHelperText)(function (_ref7) {
  var theme = _ref7.theme;
  var styles = {
    color: theme.palette.mirage.main,
    display: 'block',
    padding: theme.spacing(0, 2),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
var StyledAutocompleteTextField = styled(TextField)(function () {
  var styles = {
    '& .MuiInput-root': {
      fontSize: pxToRem(12)
    }
  };
  return styles;
});
var StyledAutocomplete = styled(Autocomplete)(function (_ref8) {
  var theme = _ref8.theme;
  var styles = {
    '& .MuiSvgIcon-root': {
      color: theme.palette.mirage.main
    }
  };
  return styles;
});
export { StyledAutocomplete, StyledAutocompleteTextField, StyledFormControl, StyledFormHelperErrorText, StyledFormHelperText, StyledInput, StyledInputBase, StyledInputLabel, StyleFormHelperWrapper };