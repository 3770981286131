import { Box, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledPublicWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    minHeight: '100vh',
    maxWidth: '100%',
    display: 'flex',
    backgroundColor: theme.palette.tetriary.main
  };
  return styles;
});
var StyledMain = styled(Box, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'inline';
  }
})(function (_ref2) {
  var theme = _ref2.theme,
      inline = _ref2.inline;
  var styles = {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1',
    width: '100%',
    paddingTop: pxToRem(60)
  };
  return styles;
});
export { StyledMain, StyledPublicWrapper };