import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { styled, Switch as MuiSwitch, Typography } from '@mui/material';
import { pxToRem } from '@themes';
var StyledSwitch = styled(MuiSwitch)(function (_ref) {
  var theme = _ref.theme,
      size = _ref.size;
  var styles = {
    opacity: 'unset',
    width: pxToRem(44),
    height: pxToRem(24),
    padding: 0,
    overflow: 'unset',
    color: theme.colors.silverChalice,
    '& .MuiSwitch-track': {
      borderRadius: pxToRem(100),
      backgroundColor: '#8B8B8B14',
      opacity: 'unset',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: pxToRem(16),
        height: pxToRem(16)
      }
    },
    '& .Mui-disabled': {
      color: theme.colors.white,
      '& + .MuiSwitch-track': {
        opacity: 'unset',
        backgroundColor: 'rgba(139, 139, 139, 0.08)'
      }
    },
    '& .Mui-checked': {
      color: theme.colors.internationalOrange,
      '& + .MuiSwitch-track': {
        opacity: 'unset',
        backgroundColor: 'rgba(139, 139, 139, 0.08)'
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.colors.internationalOrange
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: pxToRem(18),
      height: pxToRem(18),
      margin: theme.spacing(-0.75),
      backgroundColor: theme.colors.silverChalice
    }
  };

  switch (size) {
    case 'small':
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        styles = _objectSpread(_objectSpread({}, styles), {
          width: pxToRem(36),
          height: pxToRem(20),
          padding: 0,
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: pxToRem(16),
            height: pxToRem(16),
            margin: theme.spacing(-0.25)
          }
        });
        break;
      }
  }

  return styles;
});
var StyledSwitchLabel = styled(Typography)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.text.secondary
  };
  return styles;
});
export { StyledSwitch, StyledSwitchLabel };