import { PartnerType } from '@enums';
import avtor from '@images/partners/avtor.svg';
import avtoradio from '@images/partners/avtoradio.svg';
import ctt from '@images/partners/ctt.svg';
import fonbet from '@images/partners/fonbet.svg';
import haier from '@images/partners/haier.svg';
import lada from '@images/partners/lada.svg';
import mir from '@images/partners/mir.svg';
import olymp from '@images/partners/olimpbet.svg';
import rostel from '@images/partners/rostel.svg';
import sogaz from '@images/partners/sogaz.svg';
import tinkoff from '@images/partners/tinkoff.svg';
import transneft from '@images/partners/transneft.svg';
import vk from '@images/partners/vk.svg';
import yandex from '@images/partners/yandex.svg';
var KHLPartners = [{
  id: '1',
  logo: fonbet,
  title: PartnerType.TitlePartner,
  href: 'https://www.fon.bet/sports/hockey/?utm_source=khl.ru&utm_medium=cpc&utm_campaign=site_footer&utm_content=logo_footer&utm_term=evergreen&utm_referrer=https%3a%2f%2fwww.khl.ru%2f'
}, {
  id: '2',
  logo: sogaz,
  title: PartnerType.GeneralPartner,
  href: 'https://www.sogaz.ru/'
}, {
  id: '3',
  logo: tinkoff,
  title: PartnerType.GeneralPartner,
  href: 'https://www.tinkoff.ru/'
}, {
  id: '4',
  logo: mir,
  title: PartnerType.OfficialPartner,
  href: 'https://privetmir.ru/'
}, {
  id: '5',
  logo: rostel,
  title: PartnerType.Partner,
  href: 'http://www.rt.ru'
}, {
  id: '6',
  logo: lada,
  title: PartnerType.Partner,
  href: 'https://www.lada.ru/'
}, {
  id: '7',
  logo: yandex,
  title: PartnerType.Partner,
  href: 'https://plus.yandex.ru/'
}, {
  id: '8',
  logo: ctt,
  title: PartnerType.Partner,
  href: '#'
}, {
  id: '9',
  logo: haier,
  title: PartnerType.Partner,
  href: 'https://www.haier.com/ru/'
}, {
  id: '10',
  logo: avtor,
  title: PartnerType.Supplier,
  href: 'https://avtor.club/'
}, {
  id: '11',
  logo: vk,
  title: PartnerType.InformationPartner,
  href: 'https://vk.com/khl'
}, {
  id: '12',
  logo: avtoradio,
  title: PartnerType.InformationPartner,
  href: '#'
}];
var amurPartners = [{
  id: '13',
  logo: transneft,
  title: PartnerType.OfficialPartner,
  href: 'https://www.transneft.ru'
}, {
  id: '14',
  logo: olymp,
  title: PartnerType.OfficialPartner,
  href: '#'
}];
export { amurPartners, KHLPartners };