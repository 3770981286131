import { Box, IconButton, styled } from '@mui/material';
var StyledIconButtonWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    marginRight: theme.spacing(-1)
  };
  return styles;
});
var HeaderBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    padding: theme.spacing(6.75, 3, 3)
  };
  return styles;
});
var StyledIconButton = styled(IconButton)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    padding: theme.spacing(0.5)
  };
  return styles;
});
export { HeaderBox, StyledIconButton, StyledIconButtonWrapper };