import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import createReduxWaitForMiddleware from 'redux-wait-for-action';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './root.reducer';
import { rootSaga } from './root.saga'; // Create the saga middleware

var sagaMiddleware = createSagaMiddleware(); // Create the waitFor middleware

var waitForMiddleware = createReduxWaitForMiddleware();
var middleWares = [sagaMiddleware, waitForMiddleware];

if (process.env.mode === 'dev') {
  middleWares.push(logger);
} // Mount it on the Store


var store = configureStore({
  reducer: rootReducer,
  middleware: middleWares
}); // Run the saga

sagaMiddleware.run(rootSaga);
var dispatch = store.dispatch;
export { dispatch };
export default store;