import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { alpha, buttonClasses, ButtonGroup as MuiButtonGroup, buttonGroupClasses, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledButtonGroup = styled(MuiButtonGroup)(function (_ref) {
  var _rootStyles;

  var theme = _ref.theme;
  var buttonStyles = {
    borderRadius: pxToRem(12),
    borderRightColor: 'initial',
    borderLeftColor: 'initial'
  };
  var rootStyles = (_rootStyles = {
    borderRadius: pxToRem(12),
    padding: pxToRem(4),
    background: alpha(theme.palette.white.main, 0.08),
    border: 'none',
    gap: pxToRem(8)
  }, _defineProperty(_rootStyles, ".".concat(buttonGroupClasses.grouped, ":not(:last-of-type)"), buttonStyles), _defineProperty(_rootStyles, ".".concat(buttonGroupClasses.grouped, ":not(:first-of-type)"), buttonStyles), _defineProperty(_rootStyles, ".".concat(buttonGroupClasses.grouped, ".").concat(buttonClasses.text, ":hover"), {
    color: theme.colors.mercury
  }), _rootStyles);
  return rootStyles;
});
export { StyledButtonGroup };