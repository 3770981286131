export var Language;

(function (Language) {
  Language["Ru"] = "ru";
  Language["En"] = "en";
})(Language || (Language = {}));

export var UserRolesCode;

(function (UserRolesCode) {
  UserRolesCode["ADMIN"] = "ADMIN";
})(UserRolesCode || (UserRolesCode = {}));

export var PartnerType;

(function (PartnerType) {
  PartnerType["TitlePartner"] = "\u0422\u0438\u0442\u0443\u043B\u044C\u043D\u044B\u0439 \u043F\u0430\u0440\u0442\u043D\u0435\u0440";
  PartnerType["GeneralPartner"] = "\u0413\u0435\u043D\u0435\u0440\u0430\u043B\u044C\u043D\u044B\u0439 \u041F\u0430\u0440\u0442\u043D\u0435\u0440";
  PartnerType["Partner"] = "\u041F\u0430\u0440\u0442\u043D\u0435\u0440";
  PartnerType["InformationPartner"] = "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0439 \u043F\u0430\u0440\u0442\u043D\u0435\u0440";
  PartnerType["OfficialPartner"] = "\u041E\u0444\u0438\u0446\u0438\u0430\u043B\u044C\u043D\u044B\u0439 \u043F\u0430\u0440\u0442\u043D\u0435\u0440";
  PartnerType["Supplier"] = "\u041F\u043E\u0441\u0442\u0430\u0432\u0449\u0438\u043A";
})(PartnerType || (PartnerType = {}));

export var BonusVariant;

(function (BonusVariant) {
  BonusVariant["DEPOSIT"] = "DEPOSIT";
  BonusVariant["WITHDRAW"] = "WITHDRAW";
})(BonusVariant || (BonusVariant = {}));

export var FileErrorType;

(function (FileErrorType) {
  FileErrorType["FILE_SIZE"] = "FILE_SIZE";
  FileErrorType["FILE_TYPE"] = "FILE_TYPE";
  FileErrorType["NETWORK_FAIL"] = "NETWORK_FAIL";
})(FileErrorType || (FileErrorType = {}));

export var SocketEvent;

(function (SocketEvent) {
  SocketEvent["SocketDown"] = "SOCKET_DOWN";
})(SocketEvent || (SocketEvent = {}));

export var SocketConnectionState; // custom

(function (SocketConnectionState) {
  SocketConnectionState["CONNECTING"] = "Connecting";
  SocketConnectionState["CONNECTED"] = "Connected";
  SocketConnectionState["DISCONNECTED"] = "Disconnected";
})(SocketConnectionState || (SocketConnectionState = {}));

export var LoginVariant;

(function (LoginVariant) {
  LoginVariant["EMAIL"] = "EMAIL";
  LoginVariant["PHONE"] = "PHONE";
})(LoginVariant || (LoginVariant = {}));

export var Sex;

(function (Sex) {
  Sex["MALE"] = "MALE";
  Sex["FEMALE"] = "FEMALE";
})(Sex || (Sex = {}));

export var ConsistType;

(function (ConsistType) {
  ConsistType["TICKET"] = "TICKET";
  ConsistType["SEASON_TICKET"] = "SEASON_TICKET";
})(ConsistType || (ConsistType = {}));

export var ShowType;

(function (ShowType) {
  ShowType["SALE"] = "SALE";
  ShowType["ADVERTISEMENT"] = "ADVERTISEMENT";
})(ShowType || (ShowType = {}));

export var EventCardView;

(function (EventCardView) {
  EventCardView["FULL"] = "FULL";
  EventCardView["SINGLE"] = "SINGLE";
  EventCardView["EMPTY"] = "EMPTY";
})(EventCardView || (EventCardView = {}));

export var Sorting;

(function (Sorting) {
  Sorting["UNSORTED"] = "UNSORTED";
  Sorting["ASC"] = "ASC";
  Sorting["DESC"] = "DESC";
})(Sorting || (Sorting = {}));

export var OrderStatus;

(function (OrderStatus) {
  OrderStatus["CREATED"] = "CREATED";
  OrderStatus["ISSUED"] = "ISSUED";
})(OrderStatus || (OrderStatus = {}));

export var PayStatus;

(function (PayStatus) {
  PayStatus["PAID"] = "PAID";
  PayStatus["UNPAID"] = "UNPAID";
})(PayStatus || (PayStatus = {}));