import { Box, FormControl, FormHelperText, InputLabel, styled } from '@mui/material';
var StyledFormControl = styled(FormControl)(function (_ref) {
  var fullWidth = _ref.fullWidth;
  var styles = {
    display: fullWidth && 'flex',
    maxWidth: '100%'
  };
  return styles;
});
var StyledInputLabel = styled(InputLabel)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    position: 'relative',
    transform: 'unset',
    transformOrigin: 'unset',
    maxWidth: '100%',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.text.secondary,
    display: 'block',
    whiteSpace: 'unset',
    // paddingLeft:     theme.spacing(2),
    marginBottom: theme.spacing(1)
  };
  return styles;
});
var StyleFormHelperWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    marginTop: theme.typography.pxToRem(4),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    zIndex: 1
  };
  return styles;
});
var StyledFormHelperErrorText = styled(FormHelperText)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    color: theme.palette.mirage.main,
    display: 'block',
    padding: theme.spacing(0, 2),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
var StyledFormHelperText = styled(FormHelperText)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    color: theme.palette.mirage.main,
    display: 'block',
    padding: theme.spacing(0, 2),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
export { StyledFormControl, StyledFormHelperErrorText, StyledFormHelperText, StyledInputLabel, StyleFormHelperWrapper };