import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
import { Button } from '@elements';
var StyledPopupContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    padding: theme.spacing(4),
    borderRadius: theme.typography.pxToRem(16)
  }, theme.breakpoints.down('tablet'), {
    padding: theme.spacing(3)
  });

  return styles;
});
var StyledAcceptButton = styled(Button)(function (_ref2) {
  var theme = _ref2.theme,
      fullWidth = _ref2.fullWidth;
  var styles = {
    '&&': {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: fullWidth ? '100%' : '286px',
      borderRadius: theme.typography.pxToRem(12),
      backgroundColor: theme.palette.internationalOrange.main,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      color: theme.palette.white.main,
      textTransform: 'uppercase',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.internationalOrange.main,
        boxShadow: 'none'
      }
    }
  };
  return styles;
});
var StyledRejectButton = styled(Button)(function (_ref3) {
  var theme = _ref3.theme,
      fullWidth = _ref3.fullWidth;
  var styles = {
    '&&': {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: fullWidth ? '100%' : '286px',
      borderRadius: theme.typography.pxToRem(12),
      backgroundColor: 'transparent',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      color: theme.colors.jumbo,
      textTransform: 'uppercase',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        border: 'none'
      }
    }
  };
  return styles;
});
export { StyledAcceptButton, StyledPopupContainer, StyledRejectButton };