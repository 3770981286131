import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
import { Button, Typography } from '@elements';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      open = _ref.open,
      mobile = _ref.mobile;

  var styles = _defineProperty({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0',
    borderTopLeftRadius: open ? 0 : 12,
    borderTopRightRadius: open ? 0 : 12,
    visibility: 'visible',
    right: 0,
    left: 0,
    background: theme.colors.copyCodGray,
    maxWidth: '1192px',
    margin: '0 auto',
    padding: mobile ? '20px 20px 14px 20px' : '24px 20px 20px 36px',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
    zIndex: '100',
    border: "".concat(theme.typography.pxToRem(1), " solid ").concat(theme.palette.white.main)
  }, theme.breakpoints.down('desktop'), {
    border: 'none'
  });

  return styles;
});
var StyledTypography = styled(Typography)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.5px'
  };
  return styles;
});
var StyledButton = styled(Button)(function (_ref3) {
  var theme = _ref3.theme,
      fullWidth = _ref3.fullWidth;
  var styles = {
    '&&': {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: fullWidth ? '100%' : '286px',
      borderRadius: theme.typography.pxToRem(12),
      backgroundColor: theme.palette.internationalOrange.main,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      color: theme.palette.white.main,
      textTransform: 'uppercase'
    }
  };
  return styles;
});
var StyledLink = styled(Button)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    fontSize: '18px !important',
    fontStyle: 'normal !important',
    fontWeight: 500,
    lineHeight: 'normal !important',
    letterSpacing: '0.5px !important',
    color: "".concat(theme.colors.internationalOrange, " !important"),
    textTransform: 'capitalize !important',
    '&:hover': {
      backgroundColor: 'none !important'
    }
  };
  return styles;
});
export { StyledBox, StyledButton, StyledLink, StyledTypography };