import { AppBar, Container, styled, Toolbar } from '@mui/material';
import { Button, Icon, IconButton } from '@elements';
import { pxToRem } from '@themes';
var StyledAppBar = styled(AppBar)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    position: 'fixed'
  };
  return styles;
});
var StyledToolbar = styled(Toolbar)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    height: theme.typography.pxToRem(60),
    minHeight: theme.typography.pxToRem(60),
    backgroundColor: theme.colors.mineShaft,
    display: 'flex',
    justifyContent: 'space-around'
  };
  return styles;
});
var MenuButton = styled(Button, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'active';
  }
})(function (_ref3) {
  var theme = _ref3.theme,
      $color = _ref3.$color,
      active = _ref3.active;
  var styles = {
    padding: "".concat(theme.spacing(1.25, 1.5), " !important"),
    borderRadius: "".concat(pxToRem(12), " !important"),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    color: "".concat(theme.colors[$color], " !important"),
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: "".concat(active ? theme.colors.internationalOrange : theme.colors.white, " !important"),
      '& i': {
        '& svg': {
          '& g': {
            '& path': {
              fill: '#FFFFFF'
            }
          }
        }
      }
    }
  };
  return styles;
});
var StyledContainer = styled(Container)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  };
  return styles;
});
var StyledIcon = styled(Icon)(function (_ref5) {
  var theme = _ref5.theme,
      customColor = _ref5.customColor;
  var styles = {
    marginBottom: '2px',
    marginRight: theme.spacing(0.80),
    '& svg': {
      '& path': {
        fill: customColor
      }
    }
  };
  return styles;
});
var StyledIconButton = styled(IconButton)(function (_ref6) {
  var theme = _ref6.theme,
      filled = _ref6.filled;
  var styles = {
    '&:hover': {
      '& i': {
        '& svg': {
          '& g': {
            '& path': {
              fill: !filled ? '#FFFFFF' : theme.colors.internationalOrange
            }
          }
        }
      }
    }
  };
  return styles;
});
export { MenuButton, StyledAppBar, StyledContainer, StyledIcon, StyledIconButton, StyledToolbar };