import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
import { Typography } from '@elements';
import { pxToRem } from '@themes';
var KHLContainerBox = styled(Box)(function (_ref) {
  var _styles;

  var theme = _ref.theme;
  var styles = (_styles = {}, _defineProperty(_styles, theme.breakpoints.down('lg'), {
    gap: pxToRem(64),
    justifyContent: 'space-around'
  }), _defineProperty(_styles, theme.breakpoints.down('lg'), {
    gap: pxToRem(40),
    justifyContent: 'center'
  }), _styles);
  return styles;
});
var StyledTitle = styled(Typography)(function (_ref2) {
  var _styles2;

  var theme = _ref2.theme;
  var styles = (_styles2 = {}, _defineProperty(_styles2, theme.breakpoints.down('lg'), {
    fontSize: pxToRem(24),
    lineHeight: pxToRem(33)
  }), _defineProperty(_styles2, theme.breakpoints.down('md'), {
    fontSize: pxToRem(22)
  }), _styles2);
  return styles;
});
export { KHLContainerBox, StyledTitle };