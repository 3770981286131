import { produce } from 'immer';
import { CLOSE_POPUP, CLOSING_POPUP_WITH_ANSWER, HIDE_POPUP, SET_POPUP } from './popups.action-types';
var initialState = [];

var popupsReducer = function popupsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_POPUP:
      {
        return produce(state, function (draft) {
          var idx = state.findIndex(function (popup) {
            return popup.popupId === action.payload.popupId;
          });

          if (idx >= 0) {
            draft[idx] = {
              popupId: action.payload.popupId,
              isOpen: action.payload.isOpen,
              data: action.payload.data,
              accepted: false,
              rejected: false
            };
          } else {
            draft.push({
              popupId: action.payload.popupId,
              isOpen: action.payload.isOpen,
              data: action.payload.data,
              accepted: false,
              rejected: false
            });
          }
        });
      }

    case HIDE_POPUP:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (popup) {
            return popup.popupId === action.payload.popupId;
          });

          if (index !== -1) {
            draft[index].isOpen = false;
          }
        });
      }

    case CLOSE_POPUP:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (popup) {
            return popup.popupId === action.payload.popupId;
          });
          if (index !== -1) draft.splice(index, 1);
        });
      }

    case CLOSING_POPUP_WITH_ANSWER:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (popup) {
            return popup.popupId === action.payload.popupId;
          });
          if (index !== -1) draft.splice(index, 1);
        });
      }

    default:
      {
        return state;
      }
  }
};

export default popupsReducer;