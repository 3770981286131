import { produce } from 'immer';
import { CLOSE_MODAL, CLOSING_MODAL_WITH_ANSWER, HIDE_MODAL, SET_MODAL } from './modals.action-types';
var initialState = [];

var modalsReducer = function modalsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_MODAL:
      {
        return produce(state, function (draft) {
          var idx = state.findIndex(function (modal) {
            return modal.modalId === action.payload.modalId;
          });

          if (idx >= 0) {
            draft[idx] = {
              modalId: action.payload.modalId,
              isOpen: action.payload.isOpen,
              data: action.payload.data,
              accepted: false,
              rejected: false
            };
          } else {
            draft.push({
              modalId: action.payload.modalId,
              isOpen: action.payload.isOpen,
              data: action.payload.data,
              accepted: false,
              rejected: false
            });
          }
        });
      }

    case HIDE_MODAL:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (modal) {
            return modal.modalId === action.payload.modalId;
          });

          if (index !== -1) {
            draft[index].isOpen = false;
          }
        });
      }

    case CLOSE_MODAL:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (modal) {
            return modal.modalId === action.payload.modalId;
          });
          if (index !== -1) draft.splice(index, 1);
        });
      }

    case CLOSING_MODAL_WITH_ANSWER:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (modal) {
            return modal.modalId === action.payload.modalId;
          });
          if (index !== -1) draft.splice(index, 1);
        });
      }

    default:
      {
        return state;
      }
  }
};

export default modalsReducer;