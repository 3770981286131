export var Environment;

(function (Environment) {
  Environment["Dev"] = "dev";
})(Environment || (Environment = {}));

export var Feature;

(function (Feature) {
  Feature["ServiceWorker"] = "serviceWorker";
  Feature["MockAPI"] = "mockAPI";
  Feature["Socket"] = "socket";
})(Feature || (Feature = {}));