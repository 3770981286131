import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { CLOSE_SNACKBAR, CLOSING_SNACKBAR_WITH_ANSWER, HIDE_SNACKBAR, INIT_CLOSE_SNACKBAR, INIT_SNACKBAR_CLOSING_WITH_ANSWER, SET_SNACKBAR, SNACKBAR_INIT } from './snackbars.action-types';
export var actions = {
  snackbarInitClosing: function snackbarInitClosing(payload) {
    return {
      type: INIT_CLOSE_SNACKBAR,
      payload: payload
    };
  },
  snackbarClose: function snackbarClose(payload) {
    return {
      type: CLOSE_SNACKBAR,
      payload: payload
    };
  },
  snackbarInit: function snackbarInit(payload) {
    var _ref;

    return _ref = {
      type: SNACKBAR_INIT,
      payload: payload
    }, _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(SNACKBAR_INIT, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, ERROR_ACTION, "".concat(SNACKBAR_INIT, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _ref;
  },
  setSnackbar: function setSnackbar(payload) {
    return {
      type: SET_SNACKBAR,
      payload: payload
    };
  },
  snackbarInitClosingWithAnswer: function snackbarInitClosingWithAnswer(payload) {
    return {
      type: INIT_SNACKBAR_CLOSING_WITH_ANSWER,
      payload: payload
    };
  },
  closeWithAnswer: function closeWithAnswer(payload) {
    return {
      type: CLOSING_SNACKBAR_WITH_ANSWER,
      payload: payload
    };
  },
  snackbarHide: function snackbarHide(payload) {
    return {
      type: HIDE_SNACKBAR,
      payload: payload
    };
  }
};