import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Backdrop, Dialog, Paper, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledDialog = styled(Dialog)(function (_ref) {
  var theme = _ref.theme,
      $maxWidth = _ref.$maxWidth;
  var styles = {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    '& .MuiPaper-modal-root': {
      maxWidth: $maxWidth ? pxToRem($maxWidth) : undefined
    }
  };
  return styles;
});
var StyledBackDrop = styled(Backdrop)(function (_ref2) {
  var theme = _ref2.theme,
      width = _ref2.width;
  var styles = {
    backgroundColor: 'rgba(0, 0, 0, 0.60) !important',
    backdropFilter: 'blur(6px)'
  };
  return styles;
});
var StyledPaper = styled(Paper)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    borderRadius: theme.typography.pxToRem(12),
    width: "calc(100% - ".concat(theme.typography.pxToRem(64), ")"),
    backgroundColor: '#232324'
  }, theme.breakpoints.down('tablet'), {
    '&&': {
      margin: "".concat(theme.spacing(4), " ").concat(theme.spacing(2)),
      maxWidth: "calc(100% - ".concat(theme.typography.pxToRem(32), ")")
    }
  });

  return styles;
});
export { StyledBackDrop, StyledDialog, StyledPaper };