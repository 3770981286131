import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { FormLabel, RadioGroup, styled } from '@mui/material';
import { colors, pxToRem } from '@themes';
var StyledRadioGroup = styled(RadioGroup)(function (_ref) {
  var theme = _ref.theme,
      row = _ref.row;
  return _defineProperty({}, '> label', _defineProperty({
    marginLeft: pxToRem(10),
    paddingBottom: theme.spacing(2)
  }, ':last-child', {
    paddingBottom: row ? undefined : '0'
  }));
});
var StyledFormLabel = styled(FormLabel)(function (_ref3) {
  var theme = _ref3.theme;
  return {
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
    color: colors.mirage
  };
});
export { StyledFormLabel, StyledRadioGroup };