import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { produce } from 'immer';
import { HIDE_LOADER, LOADER_SHOW, REQUEST_COUNTER_DECREASE, REQUEST_COUNTER_INCREASE } from './loader.action-types';
var initialState = {
  isShowLoader: false,
  requestCounter: 0
};

var loaderReducer = function loaderReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOADER_SHOW:
      {
        return produce(state, function (draft) {
          draft.isShowLoader = true;
        });
      }

    case HIDE_LOADER:
      {
        return produce(state, function (draft) {
          draft.isShowLoader = false;
        });
      }

    case REQUEST_COUNTER_INCREASE:
      {
        return produce(state, function (draft) {
          draft.requestCounter += 1;
        });
      }

    case REQUEST_COUNTER_DECREASE:
      {
        return produce(state, function (draft) {
          draft.requestCounter -= 1;
        });
      }

    default:
      {
        return _objectSpread({}, state);
      }
  }
};

export default loaderReducer;