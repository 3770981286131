import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Chip, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledTag = styled(Chip, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'withIcon' && prop !== 'withDelete';
  }
})(function (_ref) {
  var theme = _ref.theme,
      color = _ref.color;
  var styles = {
    paddingLeft: pxToRem(10),
    minWidth: pxToRem(54),
    display: 'inline-flex',
    alignItems: 'center',
    height: pxToRem(26),
    fontSize: pxToRem(13),
    lineHeight: pxToRem(18),
    fontWeight: 500,
    borderRadius: pxToRem(8),
    '& .MuiChip-icon': {
      marginLeft: pxToRem(0),
      marginRight: pxToRem(-6),
      width: pxToRem(6),
      height: pxToRem(6),
      borderRadius: pxToRem(2)
    },
    '& > span': {
      paddingLeft: pxToRem(10),
      paddingRight: pxToRem(10)
    }
  };

  switch (color) {
    case 'jade20':
      {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          '& .chip-indicator': {
            backgroundColor: theme.colors.mirage
          }
        });
        break;
      }

    case 'brightSun20':
      {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          '& .chip-indicator': {
            backgroundColor: theme.colors.mirage
          }
        });
        break;
      }

    case 'radicalRed20':
      {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          '& .chip-indicator': {
            backgroundColor: theme.colors.mirage
          }
        });
        break;
      }

    case 'dodgerBlue20':
      {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          '& .chip-indicator': {
            backgroundColor: theme.colors.mirage
          }
        });
        break;
      }

    case 'stratos6':
      {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          '& .chip-indicator': {
            backgroundColor: theme.colors.mirage
          }
        });
        break;
      }
  }

  return styles;
});
export { StyledTag };