import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER_FAILURE, LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS } from './auth.action-types';
export var actions = {
  fetchUserRequest: function fetchUserRequest() {
    var _ref;

    return _ref = {
      type: FETCH_USER_REQUEST
    }, _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(FETCH_USER_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, ERROR_ACTION, "".concat(FETCH_USER_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _ref;
  },
  fetchUserSuccess: function fetchUserSuccess(payload) {
    return {
      type: FETCH_USER_SUCCESS,
      payload: payload
    };
  },
  fetchUserFailure: function fetchUserFailure(payload) {
    return {
      type: FETCH_USER_FAILURE,
      payload: payload
    };
  },
  loginUserRequest: function loginUserRequest(values) {
    return {
      type: LOGIN_USER_REQUEST,
      payload: {
        values: values
      }
    };
  },
  loginUserSuccess: function loginUserSuccess(payload) {
    return {
      type: LOGIN_USER_SUCCESS,
      payload: payload
    };
  },
  loginUserFailure: function loginUserFailure(payload) {
    return {
      type: LOGIN_USER_FAILURE,
      payload: payload
    };
  },
  logoutUserRequest: function logoutUserRequest() {
    return {
      type: LOGOUT_USER_REQUEST
    };
  },
  logoutUserSuccess: function logoutUserSuccess() {
    return {
      type: LOGOUT_USER_SUCCESS
    };
  },
  logoutUserFailure: function logoutUserFailure(payload) {
    return {
      type: LOGOUT_USER_FAILURE,
      payload: payload
    };
  }
};