import { createSelector } from 'reselect';

var getPending = function getPending(state) {
  return state.auth.pending;
};

var getUser = function getUser(state) {
  return state.auth.user;
};

var getError = function getError(state) {
  return state.auth.error;
};

export var getUserSelector = createSelector(getUser, function (user) {
  return user;
});
export var getPendingSelector = createSelector(getPending, function (pending) {
  return pending;
});
export var getErrorSelector = createSelector(getError, function (error) {
  return error;
});