import { useMediaQuery } from '@mui/material';
export var useDeviceSize = function useDeviceSize() {
  return {
    mobile: useMediaQuery(function (theme) {
      return theme.breakpoints.down('tablet');
    }),
    tablet: useMediaQuery(function (theme) {
      return theme.breakpoints.between('tablet', 'desktop');
    }),
    desktop: useMediaQuery(function (theme) {
      return theme.breakpoints.up('desktop');
    })
  };
};