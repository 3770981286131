import enIn from 'date-fns/locale/en-IN/index';
import ru from 'date-fns/locale/ru/index';

/**
 * Get daypicker locale.
 * @param {DatePickerProps['locale']} locale value.
 * @author Mikhail Siutsou
 * @function getLocaleFile
 * @category helpers
 * @return {Locale} locale object
 */
var getLocaleFile = function getLocaleFile(locale) {
  switch (locale) {
    case 'ru':
      return ru;

    case 'en':
      return enIn;

    default:
      return ru;
  }
};

export { getLocaleFile };