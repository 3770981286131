import { Alert, Snackbar, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledSnackbar = styled(Snackbar)(function (_ref) {
  var theme = _ref.theme,
      anchorOrigin = _ref.anchorOrigin;
  var styles = {
    zIndex: 1200
  };
  return styles;
});
var SnackbarAlert = styled(Alert)(function (_ref2) {
  var theme = _ref2.theme,
      severity = _ref2.severity;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    minWidth: pxToRem(250),
    borderRadius: pxToRem(12),
    fontWeight: 700,
    lineHeight: pxToRem(20),
    fontSize: pxToRem(16),
    '& .MuiAlert-message': {
      padding: "".concat(pxToRem(2), " 0")
    },
    '& .MuiAlert-icon': {
      padding: 0
    }
  };

  switch (severity) {
    case 'success':
      {
        break;
      }

    case 'error':
      {
        break;
      }

    case 'info':
      {
        break;
      }

    default:
      {
        break;
      }
  }

  return styles;
});
export { SnackbarAlert, StyledSnackbar };