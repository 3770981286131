import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _root;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { alpha, createTheme } from '@mui/material';
import { boxShadows } from '../box-shadows';
import { colors } from '../colors';
import { PfdintextcondproBold, PfdintextcondproLight, PfdintextcondproMedium, PfdintextcondproRegular } from '../fonts';
import { gradients } from '../gradients';

var _createTheme = createTheme({
  breakpoints: {
    values: {
      xs: 320,
      sm: 600,
      md: 768,
      lg: 1280,
      xl: 1920,
      xxl: 2560,
      mobile: 0,
      tablet: 768,
      desktop: 1280
    }
  }
}),
    breakpoints = _createTheme.breakpoints,
    DefaultPalette = _createTheme.palette,
    pxToRem = _createTheme.typography.pxToRem;

var _createTheme2 = createTheme({
  palette: {
    silverChalice: DefaultPalette.augmentColor({
      color: {
        main: colors.silverChalice
      }
    }),
    white: DefaultPalette.augmentColor({
      color: {
        main: colors.white
      }
    }),
    guardsmanRed: DefaultPalette.augmentColor({
      color: {
        main: colors.guardsmanRed
      }
    }),
    mirage: DefaultPalette.augmentColor({
      color: {
        main: colors.mirage
      }
    }),
    jumbo: DefaultPalette.augmentColor({
      color: {
        main: colors.jumbo
      }
    }),
    mercury: DefaultPalette.augmentColor({
      color: {
        main: colors.mercury
      }
    }),
    secondarySilver: DefaultPalette.augmentColor({
      color: {
        main: colors.secondarySilver
      }
    }),
    internationalOrange: DefaultPalette.augmentColor({
      color: {
        main: colors.internationalOrange
      }
    }),
    alto: DefaultPalette.augmentColor({
      color: {
        main: colors.alto
      }
    }),
    silver: DefaultPalette.augmentColor({
      color: {
        main: colors.silver
      }
    }),
    balticSea: DefaultPalette.augmentColor({
      color: {
        main: colors.balticSea
      }
    }),
    mischka: DefaultPalette.augmentColor({
      color: {
        main: colors.mischka
      }
    }),
    melrose: DefaultPalette.augmentColor({
      color: {
        main: colors.melrose
      }
    }),
    blackSecondary: DefaultPalette.augmentColor({
      color: {
        main: colors.blackSecondary
      }
    }),
    tetriary: DefaultPalette.augmentColor({
      color: {
        main: colors.tetriary
      }
    }),
    shark: DefaultPalette.augmentColor({
      color: {
        main: colors.shark
      }
    }),
    mineShaft: DefaultPalette.augmentColor({
      color: {
        main: colors.mineShaft
      }
    })
  }
}),
    palette = _createTheme2.palette;

var defaultTheme = createTheme({
  breakpoints: {
    values: breakpoints.values
  },
  palette: _objectSpread({}, palette),
  typography: {
    fontFamily: '"PF Din Text Cond Pro",sans-serif',
    h1: {
      fontSize: pxToRem(48),
      lineHeight: 'normal'
    },
    h2: {
      fontSize: pxToRem(32),
      lineHeight: 'normal'
    },
    h3: {
      fontSize: pxToRem(28),
      lineHeight: 'normal'
    },
    h4: {
      fontSize: pxToRem(20),
      lineHeight: 'normal'
    },
    h5: {
      fontSize: pxToRem(18),
      lineHeight: 'normal'
    },
    body1: {
      fontSize: pxToRem(16),
      lineHeight: 'normal'
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 'normal'
    },
    body3: {
      fontSize: pxToRem(13),
      lineHeight: 'normal'
    },
    body4: {
      fontSize: pxToRem(13),
      lineHeight: 'normal'
    },
    body5: {
      fontSize: pxToRem(12),
      lineHeight: pxToRem(14.4),
      letterSpacing: '0.24px'
    },
    caption1: {
      fontSize: pxToRem(12),
      lineHeight: 'normal'
    },
    caption2: {
      fontSize: pxToRem(10),
      lineHeight: 'normal'
    },
    button2: {
      fontSize: pxToRem(18),
      lineHeight: 'normal',
      letterSpacing: '0.5px'
    },
    overline: {
      fontSize: pxToRem(12),
      lineHeight: 'normal',
      letterSpacing: '0.03em',
      textTransform: 'none'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variant: 'body1'
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 'auto',
          height: 'auto'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.silverChalice,
          fontSize: pxToRem(16),
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textDecorationColor: alpha(colors.silverChalice, 0.4),
          cursor: 'pointer',
          textDecoration: 'none'
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.internationalOrange
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: (_root = {
          paddingLeft: 12,
          paddingRight: 12
        }, _defineProperty(_root, breakpoints.down('sm'), {
          maxWidth: 'unset'
        }), _defineProperty(_root, breakpoints.up('sm'), {
          paddingLeft: 16,
          paddingRight: 16
        }), _defineProperty(_root, breakpoints.up('md'), {
          paddingLeft: 20,
          paddingRight: 20
        }), _defineProperty(_root, breakpoints.up('lg'), {
          paddingLeft: 44,
          paddingRight: 44
        }), _root)
      }
    },
    MuiCssBaseline: {
      styleOverrides: "\n          * {\n             font-family: 'PF Din Text Cond Pro', sans-serif;\n          }\n          *[hidden] {\n            display: none !important;\n          }\n          @font-face {\n            font-family: \"PF Din Text Cond Pro\";\n            font-weight: 300;\n            font-style: normal;\n            font-display: swap;\n            src: url(".concat(PfdintextcondproLight, ") format(\"woff2\"),url(").concat(PfdintextcondproLight, ") format(\"woff\")\n          }\n          @font-face {\n            font-family: \"PF Din Text Cond Pro\";\n            font-weight: 400;\n            font-style: normal;\n            font-display: swap;\n            src: url(").concat(PfdintextcondproRegular, ") format(\"woff2\"),url(").concat(PfdintextcondproRegular, ") format(\"woff\")\n          }\n          @font-face {\n            font-family: \"PF Din Text Cond Pro\";\n            font-weight: 500;\n            font-style: normal;\n            font-display: swap;\n            src: url(").concat(PfdintextcondproMedium, ") format(\"woff2\"),url(").concat(PfdintextcondproMedium, ") format(\"woff\")\n          }\n          @font-face {\n            font-family: \"PF Din Text Cond Pro\";\n            font-weight: 700;\n            font-style: normal;\n            font-display: swap;\n            src: url(").concat(PfdintextcondproBold, ") format(\"woff2\"),url(").concat(PfdintextcondproBold, ") format(\"woff\")\n          }\n        ")
    }
  }
}, {
  colors: colors,
  gradients: gradients,
  boxShadows: boxShadows
});
export { defaultTheme, pxToRem };