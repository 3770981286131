import { createSelector } from 'reselect';

var getPopups = function getPopups(state) {
  return state.popups;
};

export var getPopupsSelector = createSelector(getPopups, function (popups) {
  return popups;
});
export var getPopupSelector = function getPopupSelector(popupId) {
  return createSelector(getPopups, function (popups) {
    return popups.find(function (popup) {
      return popup.popupId === popupId;
    });
  });
};
export var getPopupOpenStateSelector = function getPopupOpenStateSelector(popupId) {
  return createSelector([getPopupSelector(popupId)], function (popup) {
    return (popup === null || popup === void 0 ? void 0 : popup.isOpen) || false;
  });
};