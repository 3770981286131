import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { API_GRID_FAILURE, API_GRID_FETCH, API_GRID_SUCCESS, CLOSE_ALL_GRIDS, CLOSE_ALL_GRIDS_REQUEST, CLOSE_GRID, CLOSE_GRID_REQUEST, GRID_INIT, GRID_INIT_REQUEST, UPDATE_API_GRID_FETCH, UPDATE_GRID, UPDATE_GRID_REQUEST } from './grids.action-types';
export var actions = {
  gridInitRequest: function gridInitRequest(payload) {
    return {
      type: GRID_INIT_REQUEST,
      payload: payload
    };
  },
  gridInit: function gridInit(payload) {
    return {
      type: GRID_INIT,
      payload: payload
    };
  },
  gridUpdateRequest: function gridUpdateRequest(payload) {
    return {
      type: UPDATE_GRID_REQUEST,
      payload: payload
    };
  },
  gridUpdate: function gridUpdate(payload) {
    return {
      type: UPDATE_GRID,
      payload: payload
    };
  },
  gridCloseRequest: function gridCloseRequest(payload) {
    return {
      type: CLOSE_GRID_REQUEST,
      payload: payload
    };
  },
  gridClose: function gridClose(payload) {
    return {
      type: CLOSE_GRID,
      payload: payload
    };
  },
  closeAllGridsRequest: function closeAllGridsRequest() {
    return {
      type: CLOSE_ALL_GRIDS_REQUEST
    };
  },
  closeAllGrids: function closeAllGrids() {
    return {
      type: CLOSE_ALL_GRIDS
    };
  },
  gridApiFetch: function gridApiFetch(payload) {
    var _ref;

    return _ref = {
      type: API_GRID_FETCH,
      payload: payload
    }, _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(API_GRID_FETCH, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, ERROR_ACTION, "".concat(API_GRID_FETCH, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _ref;
  },
  gridUpdateApiFetch: function gridUpdateApiFetch(payload) {
    var _ref2;

    return _ref2 = {
      type: UPDATE_API_GRID_FETCH,
      payload: payload
    }, _defineProperty(_ref2, WAIT_FOR_ACTION, "".concat(UPDATE_API_GRID_FETCH, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref2, ERROR_ACTION, "".concat(UPDATE_API_GRID_FETCH, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _ref2;
  },
  gridApiSuccess: function gridApiSuccess(payload) {
    return {
      type: API_GRID_SUCCESS,
      payload: payload
    };
  },
  gridApiFailure: function gridApiFailure(payload) {
    return {
      type: API_GRID_FAILURE,
      payload: payload
    };
  }
};