import { Box, styled } from '@mui/material';
import { Popup } from '@elements';
import { pxToRem } from '@themes';
var StyledPopup = styled(Popup)(function (_ref) {
  var theme = _ref.theme;
  return {
    borderRadius: pxToRem(16)
  };
});
var StyledHeader = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  return {
    display: 'flex',
    justifyContent: 'flex-start'
  };
});
var StyledContent = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  return {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(1)
  };
});
export { StyledContent, StyledHeader, StyledPopup };