import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { IconButton as MuiIconButton, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledIconButton = styled(MuiIconButton)(function (_ref) {
  var theme = _ref.theme,
      size = _ref.size,
      color = _ref.color,
      variant = _ref.variant;
  var styles = {
    textTransform: 'none',
    boxShadow: 'none',
    minHeight: pxToRem(24),
    minWidth: pxToRem(24),
    fontFamily: '\'PF Din Text Cond Pro\', sans-serif',
    ':hover': {
      backgroundColor: '#FFFFFF08',
      boxShadow: 'none'
    }
  };

  switch (color) {
    case 'primary':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          backgroundColor: theme.palette.mirage.main,
          color: theme.palette.mirage.main,
          ':hover': {
            backgroundColor: '#FFFFFF08',
            boxShadow: 'none'
          },
          ':focus': {
            backgroundColor: theme.palette.mirage.main,
            boxShadow: "0 0 0 ".concat(pxToRem(4), " ").concat(theme.palette.mirage.main)
          },
          ':active': {
            backgroundColor: theme.palette.mirage.main,
            boxShadow: 'none'
          },
          ':disabled': {
            backgroundColor: theme.palette.mirage.main,
            boxShadow: 'none',
            color: theme.palette.mirage.main
          }
        });
        break;
      }

    case 'secondary':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          backgroundColor: theme.palette.mirage.main,
          color: theme.palette.mirage.main,
          ':hover': {
            backgroundColor: '#FFFFFF08',
            boxShadow: 'none'
          },
          ':focus': {
            backgroundColor: theme.palette.mirage.main,
            boxShadow: "0 0 0 ".concat(pxToRem(4), " ").concat(theme.palette.mirage.main)
          },
          ':active': {
            backgroundColor: theme.palette.mirage.main,
            boxShadow: 'none'
          },
          ':disabled': {
            backgroundColor: theme.palette.mirage.main,
            boxShadow: 'none',
            color: theme.palette.mirage.main
          }
        });
        break;
      }
  }

  switch (variant) {
    case 'outlined':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          backgroundColor: 'transparent',
          borderWidth: pxToRem(2),
          borderStyle: 'solid',
          color: theme.palette.mirage.main,
          borderColor: theme.palette.mirage.main,
          ':hover': {
            color: theme.palette.mirage.main,
            borderColor: theme.palette.mirage.main,
            borderWidth: pxToRem(2),
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          ':focus': {
            color: theme.palette.mirage.main,
            borderColor: theme.palette.mirage.main,
            borderWidth: pxToRem(2),
            boxShadow: "0 0 0 ".concat(pxToRem(4), " ").concat(theme.palette.mirage.main),
            backgroundColor: 'transparent'
          },
          ':active': {
            color: theme.palette.mirage.main,
            borderColor: theme.palette.mirage.main,
            borderWidth: pxToRem(2),
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          ':disabled': {
            color: theme.palette.mirage.main,
            borderColor: theme.palette.mirage.main,
            borderWidth: pxToRem(2),
            boxShadow: 'none',
            backgroundColor: 'transparent'
          }
        });
        break;
      }

    case 'text':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          backgroundColor: 'transparent',
          color: theme.palette.mirage.main,
          ':hover': {
            color: theme.palette.mirage.main,
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          ':focus': {
            color: theme.palette.mirage.main,
            backgroundColor: 'transparent',
            boxShadow: "0 0 0 ".concat(pxToRem(4), " ").concat(theme.palette.mirage.main)
          },
          ':active': {
            color: theme.palette.mirage.main,
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          ':disabled': {
            color: theme.palette.mirage.main,
            boxShadow: 'none',
            backgroundColor: 'transparent'
          }
        });
        break;
      }
  }

  switch (size) {
    case 'large':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: theme.spacing(1.75),
          borderRadius: pxToRem(10)
        });
        break;
      }

    case 'medium':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: theme.spacing(1.25),
          borderRadius: pxToRem(8)
        });
        break;
      }

    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: theme.spacing(1),
          borderRadius: pxToRem(6)
        });
        break;
      }

    case 'extraSmall':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: theme.spacing(0.875),
          borderRadius: pxToRem(6)
        });
        break;
      }
  }

  return styles;
});
export { StyledIconButton };