import { SET_THEME } from '@store/theme/theme.action-types';
import { Theme } from '@enums';
var initialState = Theme.Light;
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_THEME:
      {
        return action.theme;
      }

    default:
      {
        return state;
      }
  }
});