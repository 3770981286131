import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
import { Popup } from '@elements';
import { pxToRem } from '@themes';
var StyledPopup = styled(Popup)(function (_ref) {
  var theme = _ref.theme;
  return {
    borderRadius: pxToRem(16)
  };
});
var StyledHeader = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  return {
    display: 'flex',
    justifyContent: 'center'
  };
});
var StyledContent = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(5.75)
  }, theme.breakpoints.down('md'), {
    paddingBottom: theme.spacing(3.75)
  });
});
export { StyledContent, StyledHeader, StyledPopup };