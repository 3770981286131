import { CREATE_FORM_STATE, CREATE_FORM_STATE_ASYNC, DELETE_FORM_STATE, DELETE_FORM_STATE_ASYNC, UPDATE_FORM_STATE, UPDATE_FORM_STATE_ASYNC } from './forms.action-types';
export var actions = {
  createFormAsync: function createFormAsync(payload) {
    return {
      type: CREATE_FORM_STATE_ASYNC,
      payload: payload
    };
  },
  createForm: function createForm(payload) {
    return {
      type: CREATE_FORM_STATE,
      payload: payload
    };
  },
  updateFormStateAsync: function updateFormStateAsync(payload) {
    return {
      type: UPDATE_FORM_STATE_ASYNC,
      payload: payload
    };
  },
  updateFormState: function updateFormState(payload) {
    return {
      type: UPDATE_FORM_STATE,
      payload: payload
    };
  },
  deleteFormStateAsync: function deleteFormStateAsync(payload) {
    return {
      type: DELETE_FORM_STATE_ASYNC,
      payload: payload
    };
  },
  deleteFormState: function deleteFormState(payload) {
    return {
      type: DELETE_FORM_STATE,
      payload: payload
    };
  }
};