import { Box, Button, styled } from '@mui/material';
var StyledFormButtonsContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    marginTop: theme.spacing(6)
  };
  return styles;
});
var StyledButton = styled(Button)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    minWidth: theme.typography.pxToRem(144)
  };
  return styles;
});
export { StyledButton, StyledFormButtonsContainer };