import { combineReducers } from 'redux';
import authReducer from './auth/auth.reducer';
import formReducer from './forms/forms.reducers';
import globalVariablesReducer from './global-variables/global-variables.reducer';
import gridsReducer from './grids/grids.reducers';
import loaderReducer from './loader/loader.reducer';
import modalsReducer from './modals/modals.reducers';
import popupsReducer from './popups/popups.reducers';
import snackbarsReducer from './snackbars/snackbars.reducers';
import socketReducer from './socket/socket.reducer';
import themeReducer from './theme/theme.reducer';
var rootReducer = combineReducers({
  theme: themeReducer,
  modals: modalsReducer,
  auth: authReducer,
  loader: loaderReducer,
  forms: formReducer,
  grids: gridsReducer,
  popups: popupsReducer,
  snackbars: snackbarsReducer,
  socket: socketReducer,
  globalVariables: globalVariablesReducer
});
export default rootReducer;