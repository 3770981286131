import { Box, styled } from '@mui/material';
var StyledHeader = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  return {
    display: 'flex',
    justifyContent: 'center'
  };
});
var StyledContent = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  return {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(4)
  };
});
export { StyledContent, StyledHeader };