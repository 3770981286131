import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSocketSelector } from '@store/socket';

function useSocket(callback, events) {
  var _socketState$receiveE2, _socketState$receiveE3;

  var socketState = useSelector(getSocketSelector);
  useEffect(function () {
    if (!(events !== null && events !== void 0 && events.length)) {
      if (socketState !== null && socketState !== void 0 && socketState.receiveEvent) {
        callback(socketState === null || socketState === void 0 ? void 0 : socketState.receiveEvent);
      }
    } else {
      var _socketState$receiveE;

      var eventType = socketState === null || socketState === void 0 ? void 0 : (_socketState$receiveE = socketState.receiveEvent) === null || _socketState$receiveE === void 0 ? void 0 : _socketState$receiveE.eventType;

      if (eventType && events.indexOf(eventType) >= 0 && socketState !== null && socketState !== void 0 && socketState.receiveEvent) {
        callback(socketState === null || socketState === void 0 ? void 0 : socketState.receiveEvent);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [socketState === null || socketState === void 0 ? void 0 : (_socketState$receiveE2 = socketState.receiveEvent) === null || _socketState$receiveE2 === void 0 ? void 0 : _socketState$receiveE2.eventType, JSON.stringify(socketState === null || socketState === void 0 ? void 0 : (_socketState$receiveE3 = socketState.receiveEvent) === null || _socketState$receiveE3 === void 0 ? void 0 : _socketState$receiveE3.payload)]);
  return socketState === null || socketState === void 0 ? void 0 : socketState.receiveEvent;
}

export { useSocket };