import { DayPicker } from 'react-day-picker';
import { styled } from '@mui/material';
import { pxToRem } from '@themes';
import 'react-day-picker/dist/style.css';
var StyledDayPicker = styled(DayPicker)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    '--rdp-accent-color': theme.colors.internationalOrange,
    '--rdp-background-color': 'transparent',
    '--rdp-outline': 'none',
    '--rdp-outline-selected': 'none',
    '--rdp-cell-size': pxToRem(46),
    margin: 0,
    '& .rdp-caption_label': {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      fontWeight: 700,
      textTransform: 'uppercase',
      padding: 'unset'
    },
    '& .rdp-table': {
      borderCollapse: 'separate',
      borderSpacing: "0 ".concat(pxToRem(6)),
      marginTop: theme.spacing(2)
    },
    '& .rdp-head_row': {},
    '& .rdp-head_cell': {
      fontWeight: 500,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(20),
      color: theme.colors.white,
      textTransform: 'capitalize'
    },
    '& .rdp-weeknumber, & .rdp-day': {
      border: 'none',
      borderRadius: pxToRem(8)
    },
    '& .rdp-button': {
      fontWeight: 500,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(20),
      color: theme.palette.white.main,
      borderRadius: pxToRem(12),
      ':hover': {
        border: "".concat(pxToRem(1), " solid ").concat(theme.palette.internationalOrange.main),
        color: theme.palette.internationalOrange.main
      }
    },
    '& .rdp-button:not(.rdp-day_range_start):not(.rdp-day_range_end):not(.rdp-day_range_middle)': {
      ':hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.03)'
      }
    },
    '& .rdp-day_selected': {
      color: theme.palette.mirage.main,
      backgroundColor: theme.palette.internationalOrange.main,
      ':hover': {
        color: theme.palette.white.main
      }
    },
    '& .rdp-day_outside': {
      color: theme.palette.mischka.main
    },
    '& .rdp-day_range_end.rdp-day_range_start': {
      borderRadius: pxToRem(12)
    },
    '& .rdp-day_range_middle.rdp-day_selected': {
      borderRadius: 0,
      backgroundColor: '#1F1C1A',
      color: theme.colors.white,
      ':hover': {
        border: "".concat(pxToRem(1), " solid ").concat(theme.palette.internationalOrange.main),
        color: theme.palette.internationalOrange.main,
        borderRadius: pxToRem(12)
      }
    },
    '& .rdp-day_today:not(.rdp-day_outside)': {
      fontWeight: 'inherit'
    },
    '& .today:not(.rdp-day_range_middle):not(.rdp-day_selected)': {
      color: theme.palette.internationalOrange.main,
      backgroundColor: 'transparent',
      borderRadius: pxToRem(12),
      border: "".concat(pxToRem(1), " solid ").concat(theme.palette.internationalOrange.main),
      ':hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.03)'
      }
    },
    '& .rdp-tfoot': {
      margin: 'unset'
    }
  };
  return styles;
});
export { StyledDayPicker };