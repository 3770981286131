function Retry(fn) {
  var retriesLeft = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  var interval = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000;
  return new Promise(function (resolve, reject) {
    fn().then(resolve)["catch"](function (error) {
      setTimeout(function () {
        if (retriesLeft === 1) {
          // reject('maximum retries exceeded');
          reject(error); // Refresh page for get new index.html

          window.location.reload();
          return;
        } // Passing on "reject" is the important part


        Retry(fn, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
  });
}

export { Retry };