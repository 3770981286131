import { HIDE_LOADER, INIT_LOADER_HIDING, INIT_LOADER_SHOWING, INIT_REQUEST_COUNTER_DECREASING, INIT_REQUEST_COUNTER_INCREASING, LOADER_SHOW, REQUEST_COUNTER_DECREASE, REQUEST_COUNTER_INCREASE } from '@store/loader/loader.action-types';
export var actions = {
  initLoaderShowing: function initLoaderShowing() {
    return {
      type: INIT_LOADER_SHOWING
    };
  },
  showLoader: function showLoader() {
    return {
      type: LOADER_SHOW
    };
  },
  initLoaderHiding: function initLoaderHiding() {
    return {
      type: INIT_LOADER_HIDING
    };
  },
  hideLoader: function hideLoader() {
    return {
      type: HIDE_LOADER
    };
  },
  initIncreasingRequestCounter: function initIncreasingRequestCounter() {
    return {
      type: INIT_REQUEST_COUNTER_INCREASING
    };
  },
  increaseRequestCounter: function increaseRequestCounter() {
    return {
      type: REQUEST_COUNTER_INCREASE
    };
  },
  initDecreasingRequestCounter: function initDecreasingRequestCounter() {
    return {
      type: INIT_REQUEST_COUNTER_DECREASING
    };
  },
  decreaseRequestCounter: function decreaseRequestCounter() {
    return {
      type: REQUEST_COUNTER_DECREASE
    };
  }
};