import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { produce } from 'immer';
import { FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER_FAILURE, LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS } from './auth.action-types';
var initialState = {
  pending: false,
  user: null,
  error: null
};
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_USER_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }

    case FETCH_USER_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = action.payload.user;
          draft.error = null;
        });
      }

    case FETCH_USER_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = null;
          draft.error = action.payload.error;
        });
      }

    case LOGIN_USER_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }

    case LOGIN_USER_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.user = action.payload.user;
          draft.pending = false;
          draft.error = null;
        });
      }

    case LOGIN_USER_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = null;
          draft.error = action.payload.error;
        });
      }

    case LOGOUT_USER_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }

    case LOGOUT_USER_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = null;
          draft.error = null;
        });
      }

    case LOGOUT_USER_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
        });
      }

    default:
      {
        return _objectSpread({}, state);
      }
  }
});