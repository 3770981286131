export var ApiResponse;

(function (ApiResponse) {
  ApiResponse[ApiResponse["BadRequest"] = 400] = "BadRequest";
  ApiResponse[ApiResponse["Unauthorized"] = 401] = "Unauthorized";
  ApiResponse[ApiResponse["Forbidden"] = 403] = "Forbidden";
  ApiResponse[ApiResponse["NotFound"] = 404] = "NotFound";
  ApiResponse[ApiResponse["InvalidToken"] = 498] = "InvalidToken";
  ApiResponse[ApiResponse["ServerError"] = 500] = "ServerError";
  ApiResponse[ApiResponse["BadGateway"] = 502] = "BadGateway";
})(ApiResponse || (ApiResponse = {}));