import { produce } from 'immer';
import { API_GRID_FAILURE, API_GRID_SUCCESS, CLOSE_ALL_GRIDS, CLOSE_GRID, GRID_INIT, UPDATE_GRID } from './grids.action-types';
var initialState = [];

var gridsReducer = function gridsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GRID_INIT:
    case UPDATE_GRID:
    case API_GRID_SUCCESS:
    case API_GRID_FAILURE:
      {
        return produce(state, function (draft) {
          var idx = state.findIndex(function (grid) {
            return grid.gridId === action.payload.gridId;
          });

          if (idx >= 0) {
            draft[idx] = {
              gridId: action.payload.gridId,
              items: action.payload.items || state[idx].items,
              isError: action.payload.isError,
              isLoading: action.payload.isLoading
            };
          } else {
            draft.push({
              gridId: action.payload.gridId,
              items: action.payload.items,
              isError: action.payload.isError,
              isLoading: action.payload.isLoading
            });
          }
        });
      }

    case CLOSE_GRID:
      {
        return produce(state, function (draft) {
          var idx = state.findIndex(function (grid) {
            return grid.gridId === action.payload.gridId;
          });
          draft.splice(idx, 1);
        });
      }

    case CLOSE_ALL_GRIDS:
      {
        return [];
      }

    default:
      {
        return state;
      }
  }
};

export default gridsReducer;