import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledLink = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    alignItems: 'end',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: theme.spacing(0, 2),
    '&:hover': {
      '& i': {
        '& svg': {
          '& g': {
            '& path': {
              color: '#FFFFFF'
            }
          }
        }
      }
    }
  }, theme.breakpoints.down('md'), {
    padding: theme.spacing(0, 1)
  });

  return styles;
});
export { StyledLink };