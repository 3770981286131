import { actions } from '@store/loader';
var LoaderService = {
  showLoader: function showLoader(dispatch) {
    dispatch(actions.initLoaderShowing());
  },
  hideLoader: function hideLoader(dispatch) {
    dispatch(actions.initLoaderHiding());
  },
  incRequestCounter: function incRequestCounter(dispatch) {
    dispatch(actions.initIncreasingRequestCounter());
  },
  decRequestCounter: function decRequestCounter(dispatch) {
    dispatch(actions.initDecreasingRequestCounter());
  }
};
export { LoaderService };