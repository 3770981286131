import { createSelector } from 'reselect';

var getSnackbars = function getSnackbars(state) {
  return state.snackbars;
};

export var getSnackbarsSelector = createSelector(getSnackbars, function (snackbars) {
  return snackbars;
});
export var getSnackbarSelector = function getSnackbarSelector(snackbarId) {
  return createSelector(getSnackbars, function (snackbars) {
    return snackbars.find(function (snackbar) {
      return snackbar.snackbarId === snackbarId;
    });
  });
};
export var getSnackbarOpenStateSelector = function getSnackbarOpenStateSelector(snackbarId) {
  return createSelector([getSnackbarSelector(snackbarId)], function (snackbar) {
    return (snackbar === null || snackbar === void 0 ? void 0 : snackbar.isOpen) || false;
  });
};