import { createSelector } from 'reselect';

/**
 * Get global variables state.
 *
 * @author Mikhail Siutsou
 * @function getGlobalVariablesState
 * @category Selectors
 * @subcategory Global variables
 * @param {AppState} state state.
 * @return {GlobalVariablesState} global variables state.
 */
var getGlobalVariablesState = function getGlobalVariablesState(state) {
  return state.globalVariables;
};
/**
 * Get all global variables.
 *
 * @author Mikhail Siutsou
 * @function getGlobalVariablesSelector
 * @category Selectors
 * @subcategory Global variables
 */


export var getGlobalVariablesSelector = createSelector(getGlobalVariablesState, function (globalVariables) {
  return globalVariables;
});
/**
 * Get global variable by key.
 *
 * @author Mikhail Siutsou
 * @function getGlobalVariablesSelector
 * @category Selectors
 * @subcategory Global variables
 */

export var getGlobalVariableSelector = function getGlobalVariableSelector(key) {
  return createSelector(getGlobalVariablesState, function (globalVariables) {
    return globalVariables[key];
  });
};