import { differenceInDays } from 'date-fns/esm';
import { convertedDateToObject } from '@helpers';

/**
 * Change date handler.
 *
 * @author Mikhail Siutsou
 * @param {{to: RangeButtonProps['to'];from: RangeButtonProps['from'];}} range value.
 * @function getButtonProps
 * @category helpers
 * @return {{ color: ButtonProps['color'] }}
 */
var getButtonProps = function getButtonProps(range, selectedRange) {
  var rangeFrom = convertedDateToObject(range.from);
  var rangeTo = convertedDateToObject(range.to); // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  return selectedRange.to && selectedRange.from && rangeFrom && rangeTo && differenceInDays(selectedRange.from, rangeFrom) === 0 && differenceInDays(selectedRange.to, rangeTo) === 0 ? {
    color: 'primary'
  } : {
    color: 'whisper'
  };
};

export { getButtonProps };