import { alpha, styled } from '@mui/material';
var ArrowStyle = styled('span')(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    top: theme.typography.pxToRem(-7),
    zIndex: 1,
    width: theme.typography.pxToRem(12),
    right: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(12),
    content: "''",
    position: 'absolute',
    borderRadius: "0 0 ".concat(theme.typography.pxToRem(4), " 0"),
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper,
    borderRight: "solid ".concat(theme.typography.pxToRem(1), " ").concat(alpha(theme.palette.grey[500], 0.12)),
    borderBottom: "solid ".concat(theme.typography.pxToRem(1), " ").concat(alpha(theme.palette.grey[500], 0.12))
  };
  return styles;
});
export { ArrowStyle };