import { DELETE_VALUE, SET_VALUE } from './global-variables.action-types';
export var actions = {
  /**
   * Set value.
   *
   * @author Mikhail Siutsou
   * @function setValue
   * @category Actions
   */
  setValue: function setValue(payload) {
    return {
      type: SET_VALUE,
      payload: payload
    };
  },

  /**
   * Delete value.
   *
   * @author Mikhail Siutsou
   * @function deleteValue
   * @category Actions
   */
  deleteValue: function deleteValue(payload) {
    return {
      type: DELETE_VALUE,
      payload: payload
    };
  }
};