import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Checkbox, FormControlLabel, styled } from '@mui/material';
import { Icon } from '@elements/icon';
import { pxToRem } from '@themes';
var StyledFormControlLabel = styled(FormControlLabel)(function (_ref) {
  var theme = _ref.theme;
  return {
    '&.MuiFormControlLabel-root': {
      margin: 0,
      padding: 0,
      '& .MuiButtonBase-root': {
        margin: 0,
        padding: 0
      },
      '& .MuiTypography-root': _objectSpread(_objectSpread({}, theme.typography.body1), {}, {
        margin: "0 0 0 ".concat(pxToRem(12))
      }),
      '& .Mui-disabled': {
        color: theme.palette.mirage
      }
    }
  };
});
var StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'fullWidth' && prop !== 'error';
  }
})(function (_ref2) {
  var theme = _ref2.theme,
      size = _ref2.size;
  var styles = {
    padding: 0,
    // unchecked behavior
    '& svg': {
      width: pxToRem(22),
      height: pxToRem(22),
      '#checkboxChecked': {
        display: 'none'
      },
      '#checkboxIndeterminate': {
        display: 'none'
      },
      '#checkboxFirstRect': {
        fill: theme.colors.white
      },
      '#checkboxLastRect': {
        stroke: theme.colors.mirage
      },
      '& g': {
        filter: 'none'
      }
    },
    '&:hover': {
      '& svg': {
        '#checkboxChecked': {
          display: 'block',
          stroke: theme.colors.mirage
        },
        '#checkboxLastRect': {
          stroke: theme.colors.mirage
        }
      }
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.mirage.main,
      '& svg': {
        '#checkboxFirstRect': {
          fill: theme.colors.white
        },
        '#checkboxLastRect': {
          stroke: theme.colors.white
        },
        '& g': {
          filter: 'url(#filter0_d_116_16339)'
        }
      }
    },
    '&.Mui-disabled': {
      '& svg': {
        '#checkboxFirstRect': {
          fill: theme.colors.mirage
        }
      }
    },
    // checked behavior
    '&.Mui-checked': {
      '& svg': {
        '#checkboxChecked': {
          display: 'block',
          stroke: theme.colors.white
        },
        '#checkboxFirstRect': {
          stroke: theme.colors.mirage,
          fill: theme.colors.mirage
        },
        '#checkboxLastRect': {
          stroke: theme.colors.mirage
        }
      },
      '&:hover': {
        '& svg': {
          '#checkboxChecked': {
            display: 'block',
            stroke: theme.colors.white
          },
          '#checkboxFirstRect': {
            stroke: theme.colors.mirage,
            fill: theme.colors.mirage
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage
          }
        }
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.mirage.main,
        '& svg': {
          '#checkboxChecked': {
            display: 'block',
            stroke: theme.colors.white
          },
          '#checkboxFirstRect': {
            fill: theme.colors.mirage
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage
          },
          '& g': {
            filter: 'url(#filter0_d_116_16339)'
          }
        }
      },
      '&.Mui-disabled': {
        '& svg': {
          '#checkboxChecked': {
            display: 'block',
            stroke: theme.colors.mirage
          },
          '#checkboxFirstRect': {
            stroke: theme.colors.mirage,
            fill: theme.colors.mirage
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage
          }
        }
      }
    },
    // indeterminate behavior
    '&.MuiCheckbox-indeterminate': {
      '& svg': {
        '#checkboxIndeterminate': {
          display: 'block'
        },
        '#checkboxFirstRect': {
          stroke: theme.colors.mirage,
          fill: theme.colors.mirage
        },
        '#checkboxLastRect': {
          stroke: theme.colors.mirage
        }
      },
      '&:hover': {
        '& svg': {
          '#checkboxChecked': {
            display: 'none'
          },
          '#checkboxIndeterminate': {
            display: 'block',
            fill: theme.colors.white
          },
          '#checkboxFirstRect': {
            stroke: theme.colors.mirage,
            fill: theme.colors.mirage
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage
          }
        }
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.mirage.main,
        '& svg': {
          '#checkboxIndeterminate': {
            display: 'block',
            stroke: theme.colors.white
          },
          '#checkboxFirstRect': {
            fill: theme.colors.mirage
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage
          },
          '& g': {
            filter: 'url(#filter0_d_116_16339)'
          }
        }
      },
      '&.Mui-disabled': {
        '& svg': {
          '#checkboxIndeterminate': {
            display: 'block',
            fill: theme.colors.mirage,
            stroke: 'none'
          },
          '#checkboxFirstRect': {
            stroke: theme.colors.mirage,
            fill: theme.colors.mirage
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage
          }
        }
      }
    }
  };

  switch (size) {
    case 'medium':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          '& svg': _objectSpread(_objectSpread({}, styles['& svg']), {}, {
            width: pxToRem(22),
            height: pxToRem(22)
          })
        });
        break;
      }

    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          '& svg': _objectSpread(_objectSpread({}, styles['& svg']), {}, {
            width: pxToRem(18),
            height: pxToRem(18)
          })
        });
        break;
      }
  }

  return styles;
});
var StyledIcon = styled(Icon)(function (_ref3) {
  var theme = _ref3.theme;
  return {
    height: '100%',
    width: '100%',
    padding: 0
  };
});
export { StyledCheckbox, StyledFormControlLabel, StyledIcon };