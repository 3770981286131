import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Backdrop, Dialog, Paper, styled } from '@mui/material';
var StyledDialog = styled(Dialog)(function (_ref) {
  var theme = _ref.theme;
  var styles = {};
  return styles;
});
var StyledPaper = styled(Paper)(function (_ref2) {
  var theme = _ref2.theme,
      width = _ref2.width;

  var styles = _defineProperty({
    borderRadius: theme.typography.pxToRem(12),
    maxWidth: width ? theme.typography.pxToRem(width) : undefined,
    backgroundColor: '#232324'
  }, theme.breakpoints.down('md'), {
    margin: "".concat(theme.spacing(4), " ").concat(theme.spacing(2)),
    width: "calc(100% - ".concat(theme.typography.pxToRem(32), ")")
  });

  return styles;
});
var StyledBackDrop = styled(Backdrop)(function (_ref3) {
  var theme = _ref3.theme,
      width = _ref3.width;
  var styles = {
    backgroundColor: 'rgba(0, 0, 0, 0.60) !important',
    backdropFilter: 'blur(6px)'
  };
  return styles;
});
export { StyledBackDrop, StyledDialog, StyledPaper };