import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Divider, styled } from '@mui/material';
import { Typography } from '@elements';
import { pxToRem } from '@themes';
var StyledHeaderTypography = styled(Typography)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.5px'
  };
  return styles;
});
var StyledBodyTypography = styled(Typography)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.5px'
  };
  return styles;
});
var StyledRightBox = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {};
  return styles;
});
var StyledDivider = styled(Divider)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    height: pxToRem(117),
    marginTop: pxToRem(-20),
    position: 'absolute',
    top: '0',
    borderStyle: 'dashed',
    borderColor: theme.colors.tetriary,
    borderRightWidth: 'medium',
    left: '0'
  };
  return styles;
});
var Puller = styled('div')(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    width: '200px',
    height: 6,
    backgroundColor: theme.colors.white,
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: '50%',
    transform: 'translateX(-50%)'
  }, theme.breakpoints.down('md'), {
    width: '80px'
  });

  return styles;
});
export { Puller, StyledBodyTypography, StyledDivider, StyledHeaderTypography, StyledRightBox };