import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { alpha, Box, FormControl, FormHelperText, InputLabel, styled, TextareaAutosize } from '@mui/material';
var StyledFormControl = styled(FormControl)(function (_ref) {
  var fullWidth = _ref.fullWidth;
  var styles = {
    display: fullWidth && 'flex',
    maxWidth: '100%'
  };
  return styles;
});
var StyledTextareaBase = styled(TextareaAutosize)(function (_ref2) {
  var _styles;

  var theme = _ref2.theme,
      size = _ref2.size,
      error = _ref2.error,
      fullWidth = _ref2.fullWidth;
  var styles = (_styles = {
    border: "".concat(theme.typography.pxToRem(1), " solid ").concat(theme.palette.mirage.main),
    borderRadius: theme.typography.pxToRem(12),
    overflow: 'hidden',
    transition: theme.transitions.create(['border-color']),
    boxSizing: 'border-box',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 500,
    color: theme.palette.mirage.main,
    position: 'relative',
    height: theme.typography.pxToRem(48),
    padding: theme.spacing(1.5, 1.375),
    width: fullWidth ? '100%' : undefined,
    resize: 'none',
    '&:hover': {
      borderColor: theme.palette.mirage.main
    }
  }, _defineProperty(_styles, "&:focus", {
    borderColor: theme.palette.mirage.main,
    outline: "".concat(theme.typography.pxToRem(4), " solid ").concat(alpha(theme.palette.mirage.main, 0.2))
  }), _defineProperty(_styles, "&:disabled", {
    color: theme.palette.mirage.main,
    borderColor: theme.palette.mirage.main,
    backgroundColor: theme.palette.mirage.main
  }), _defineProperty(_styles, '&::placeholder', {
    color: theme.palette.mirage.main,
    opacity: 1
  }), _styles);

  if (error) {
    styles.borderColor = theme.palette.mirage.main; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    styles['&:hover'].outline = 'none'; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    styles['&:hover'].borderColor = theme.palette.mirage.main; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    styles['&:focus'].outline = "".concat(theme.typography.pxToRem(4), " solid ").concat(alpha(theme.palette.mirage.main, 0.2)); // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    styles['&:focus'].borderColor = theme.palette.mirage.main;
  }

  switch (size) {
    case 'large':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          height: theme.typography.pxToRem(56),
          padding: theme.spacing(2.25, 2)
        });
        break;
      }

    case 'medium':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          height: theme.typography.pxToRem(48),
          padding: theme.spacing(1.5, 1.375)
        });
        break;
      }

    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          height: theme.typography.pxToRem(40),
          padding: theme.spacing(1.25, 1.125),
          fontSize: theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(18)
        });
        break;
      }
  }

  return styles;
});
var StyledInputLabel = styled(InputLabel)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    position: 'relative',
    transform: 'unset',
    transformOrigin: 'unset',
    maxWidth: '100%',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 500,
    color: theme.palette.mirage.main,
    display: 'block',
    whiteSpace: 'unset',
    marginBottom: theme.typography.pxToRem(6),
    '&.Mui-focused': {
      color: theme.palette.mirage.main
    },
    '&.Mui-error': {
      color: theme.palette.mirage.main
    }
  };
  return styles;
});
var StyleFormHelperWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    zIndex: 1,
    marginTop: 0
  };
  return styles;
});
var StyledFormHelperErrorText = styled(FormHelperText)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.mirage.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding: theme.spacing(0),
    margin: 'unset',
    maxWidth: '100%',
    '&.Mui-error': {
      color: theme.palette.mirage.main
    }
  };
  return styles;
});
var StyledFormHelperWarningText = styled(FormHelperText)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.mirage.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding: theme.spacing(0),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
var StyledFormHelperSuccessText = styled(FormHelperText)(function (_ref7) {
  var theme = _ref7.theme;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.mirage.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding: theme.spacing(0),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
var StyledFormHelperText = styled(FormHelperText)(function (_ref8) {
  var theme = _ref8.theme;
  var styles = {
    display: 'block',
    padding: theme.spacing(0),
    margin: 'unset',
    maxWidth: '100%',
    color: theme.palette.mirage.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18)
  };
  return styles;
});
export { StyledFormControl, StyledFormHelperErrorText, StyledFormHelperSuccessText, StyledFormHelperText, StyledFormHelperWarningText, StyledInputLabel, StyledTextareaBase, StyleFormHelperWrapper };