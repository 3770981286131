import { createSelector } from 'reselect';

var getModals = function getModals(state) {
  return state.modals;
};

export var getModalsSelector = createSelector(getModals, function (modals) {
  return modals;
});
export var getModalSelector = function getModalSelector(modalId) {
  return createSelector(getModals, function (modals) {
    return modals.find(function (modal) {
      return modal.modalId === modalId;
    });
  });
};
export var getModalOpenStateSelector = function getModalOpenStateSelector(modalId) {
  return createSelector([getModalSelector(modalId)], function (modal) {
    return (modal === null || modal === void 0 ? void 0 : modal.isOpen) || false;
  });
};
export var getModalDataSelector = function getModalDataSelector(modalId) {
  return createSelector([getModalSelector(modalId)], function (modal) {
    return (modal === null || modal === void 0 ? void 0 : modal.data) || false;
  });
};