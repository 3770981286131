import { dispatch } from '@store';
import { actions } from '@store/grids';
var GridsService = {
  init: function init(gridId, items, isLoading, isError) {
    dispatch(actions.gridInitRequest({
      gridId: gridId,
      items: items,
      isLoading: isLoading,
      isError: isError
    }));
  },
  update: function update(gridId, items, isLoading, isError) {
    dispatch(actions.gridUpdateRequest({
      gridId: gridId,
      items: items,
      isLoading: isLoading,
      isError: isError
    }));
  },
  close: function close(gridId) {
    dispatch(actions.gridCloseRequest({
      gridId: gridId
    }));
  },
  closeAll: function closeAll() {
    dispatch(actions.closeAllGridsRequest());
  },
  api: function api(gridId, _api, transformResponse) {
    var asyncDispatch = dispatch;
    return asyncDispatch(actions.gridApiFetch({
      gridId: gridId,
      api: _api,
      transformResponse: transformResponse
    }));
  },
  apiUpdate: function apiUpdate(gridId, api, transformResponse) {
    var asyncDispatch = dispatch;
    return asyncDispatch(actions.gridUpdateApiFetch({
      gridId: gridId,
      api: api,
      transformResponse: transformResponse
    }));
  }
};
export { GridsService };