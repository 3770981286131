import { dispatch } from '@store';
import { LoaderService } from '@services';

var LoaderInterceptor = function LoaderInterceptor(_ref) {
  var response = _ref.response,
      request = _ref.request,
      error = _ref.error;

  if (request && request.blocked) {
    LoaderService.incRequestCounter(dispatch);
  }

  if (response && response.config.blocked) {
    LoaderService.decRequestCounter(dispatch);
  }

  if (error && error.config.blocked) {
    LoaderService.decRequestCounter(dispatch);
  }

  return request || response || error && Promise.reject(error);
};

export { LoaderInterceptor };