import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledFormControl = styled(FormControl)(function (_ref) {
  var fullWidth = _ref.fullWidth;
  var styles = {
    display: fullWidth && 'flex',
    maxWidth: '100%'
  };
  return styles;
});
var StyledCheckboxBase = styled(Checkbox, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'fullWidth';
  }
})(function (_ref2) {
  var theme = _ref2.theme,
      color = _ref2.color;

  var styles = _defineProperty({
    padding: theme.spacing(1)
  }, '& .MuiSvgIcon-root', {
    height: pxToRem(20)
  });

  return styles;
});
var StyleFromHelperWrapper = styled(Box)(function () {
  var styles = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    marginTop: pxToRem(4),
    fontSize: pxToRem(12),
    lineHeight: pxToRem(14)
  };
  return styles;
});
var StyledFormHelperErrorText = styled(FormHelperText)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    '&&': {
      color: theme.palette.mirage.main,
      display: 'block',
      padding: theme.spacing(0, 1),
      margin: 'unset',
      maxWidth: '100%'
    }
  };
  return styles;
});
var StyledFormHelperText = styled(FormHelperText)(function (_ref4) {
  var theme = _ref4.theme,
      $color = _ref4.$color;
  var styles = {
    color: theme.palette.mirage.main,
    display: 'block',
    padding: theme.spacing(0, 1),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
var StyledCheckboxLabel = styled(FormControlLabel, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'labelAlign';
  }
})(function (_ref5) {
  var theme = _ref5.theme,
      labelAlign = _ref5.labelAlign;
  var styles = {
    marginLeft: theme.spacing(-1),
    '&.Mui-disabled': {
      '& .MuiCheckbox-root': {
        opacity: 0.5
      },
      '& .MuiTypography-root': {
        color: theme.palette.mirage.main
      }
    }
  };

  if (labelAlign === 'top') {
    styles.alignItems = 'flex-start';
  }

  return styles;
});
export { StyledCheckboxBase, StyledCheckboxLabel, StyledFormControl, StyledFormHelperErrorText, StyledFormHelperText, StyleFromHelperWrapper };