import { produce } from 'immer';
import { CLOSE_SNACKBAR, CLOSING_SNACKBAR_WITH_ANSWER, HIDE_SNACKBAR, SET_SNACKBAR } from './snackbars.action-types';
var initialState = [];

var snackbarsReducer = function snackbarsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_SNACKBAR:
      {
        return produce(state, function (draft) {
          var idx = state.findIndex(function (snackbar) {
            return snackbar.snackbarId === action.payload.snackbarId;
          });

          if (idx >= 0) {
            draft[idx] = {
              snackbarId: action.payload.snackbarId,
              isOpen: action.payload.isOpen,
              data: action.payload.data,
              accepted: false,
              rejected: false
            };
          } else {
            draft.push({
              snackbarId: action.payload.snackbarId,
              isOpen: action.payload.isOpen,
              data: action.payload.data,
              accepted: false,
              rejected: false
            });
          }
        });
      }

    case HIDE_SNACKBAR:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (snackbar) {
            return snackbar.snackbarId === action.payload.snackbarId;
          });

          if (index !== -1) {
            draft[index].isOpen = false;
          }
        });
      }

    case CLOSE_SNACKBAR:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (snackbar) {
            return snackbar.snackbarId === action.payload.snackbarId;
          });
          if (index !== -1) draft.splice(index, 1);
        });
      }

    case CLOSING_SNACKBAR_WITH_ANSWER:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (snackbar) {
            return snackbar.snackbarId === action.payload.snackbarId;
          });
          if (index !== -1) draft.splice(index, 1);
        });
      }

    default:
      {
        return state;
      }
  }
};

export default snackbarsReducer;