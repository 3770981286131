import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { alpha, Box, Popover, popoverClasses, styled } from '@mui/material';
import { TextField } from '@elements/form/fields/text-field';
import { pxToRem } from '@themes';
var StyledPopover = styled(Popover)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({}, "& .".concat(popoverClasses.paper), _defineProperty({
    padding: pxToRem(20),
    borderRadius: pxToRem(16),
    borderColor: theme.colors.gray,
    backgroundColor: '#242424',
    boxShadow: "0 ".concat(pxToRem(3), " ").concat(pxToRem(5), " ").concat(alpha(theme.colors.blackSecondary, 0.17))
  }, theme.breakpoints.down(380), {
    maxWidth: 'calc(100% - 16px)'
  }));

  return styles;
});
var StyledDayPickerButtonsWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    paddingTop: theme.spacing(2.5),
    marginTop: theme.spacing(2.5)
  };
  return styles;
});
var TimeTextField = styled(TextField)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    borderRadius: pxToRem(6),
    '& .MuiInputBase-input': {
      height: pxToRem(26),
      padding: theme.spacing(0.5, 1.5)
    }
  };
  return styles;
});
export { StyledDayPickerButtonsWrapper, StyledPopover, TimeTextField };