import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Box, styled, Tab, tabClasses } from '@mui/material';
import { pxToRem } from '@themes';
var StyledCountBoxIcon = styled(Box, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'iconPosition';
  }
})(function (_ref) {
  var theme = _ref.theme,
      iconPosition = _ref.iconPosition;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: "".concat(theme.colors.mirage, " !important"),
    color: theme.colors.mirage,
    padding: "".concat(theme.spacing(0.25), " ").concat(theme.spacing(0.75)),
    borderRadius: pxToRem(13)
  };

  switch (iconPosition) {
    case 'start':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          marginRight: pxToRem(8)
        });
        break;
      }

    case 'end':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          marginLeft: pxToRem(8)
        });
        break;
      }

    case 'top':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          marginBottom: pxToRem(6)
        });
        break;
      }

    case 'bottom':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          marginTop: pxToRem(6)
        });
        break;
      }

    default:
      {
        break;
      }
  }

  return styles;
});
var StyledTab = styled(Tab, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'viewVariant';
  }
})( // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function (_ref2) {
  var _objectSpread2;

  var theme = _ref2.theme,
      viewVariant = _ref2.viewVariant,
      size = _ref2.size,
      hidden = _ref2.hidden;

  var styles = _objectSpread(_objectSpread(_objectSpread({}, theme.typography.body3), {}, {
    color: theme.colors.mirage,
    textTransform: 'unset',
    padding: "".concat(theme.spacing(1.5), " ").concat(theme.spacing(1), "}"),
    height: pxToRem(36),
    minHeight: 'unset'
  }, hidden ? {
    width: 0,
    minWidth: 'unset',
    minHeight: 'unset',
    overflow: 'hidden',
    padding: 'unset',
    pointerEvents: 'none',
    fontSize: 0,
    height: 'unset'
  } : {}), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, "&.".concat(tabClasses.selected), _defineProperty({
    color: theme.colors.mirage
  }, "".concat(StyledCountBoxIcon), {
    backgroundColor: "".concat(theme.palette.mirage.main, " !important"),
    color: theme.colors.mirage
  })), _defineProperty(_objectSpread2, "&.".concat(tabClasses.disabled), {
    color: theme.palette.mirage
  }), _defineProperty(_objectSpread2, "&:hover:not(&.".concat(tabClasses.selected, ")"), _defineProperty({
    color: theme.colors.mirage,
    backgroundColor: theme.palette.mirage.main,
    borderRadius: pxToRem(8)
  }, "".concat(StyledCountBoxIcon), {
    backgroundColor: "".concat(theme.palette.mirage.main, " !important"),
    color: theme.colors.mirage
  })), _objectSpread2));

  switch (viewVariant) {
    case 'contained':
      {
        var _objectSpread3;

        styles = _objectSpread(_objectSpread({}, styles), (_objectSpread3 = {
          transition: 'all 300ms cubic-bezier(0.4,0,0.2,1) 0ms'
        }, _defineProperty(_objectSpread3, "&.".concat(tabClasses.selected), _defineProperty({
          backgroundColor: theme.colors.mirage,
          color: theme.colors.white,
          borderRadius: pxToRem(8)
        }, "".concat(StyledCountBoxIcon), {
          backgroundColor: "".concat(theme.colors.white, " !important"),
          color: theme.colors.mirage
        })), _defineProperty(_objectSpread3, "&.".concat(tabClasses.disabled), {
          color: theme.palette.mirage.main,
          backgroundColor: theme.colors.mirage,
          borderRadius: pxToRem(8),
          opacity: 'unset'
        }), _objectSpread3));
        break;
      }

    default:
      {
        break;
      }
  }

  switch (size) {
    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), _defineProperty({
          padding: "".concat(theme.spacing(0.5), " ").concat(theme.spacing(1), "}"),
          height: pxToRem(26)
        }, "".concat(StyledCountBoxIcon), {
          padding: "".concat(theme.spacing(0.125), " ").concat(theme.spacing(0.75))
        }));
        break;
      }

    default:
      {
        break;
      }
  }

  return styles;
});
export { StyledCountBoxIcon, StyledTab };