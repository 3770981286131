import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { alpha, Chip, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledChip = styled(Chip, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'withIcon' && prop !== 'withDelete';
  }
})(function (_ref) {
  var theme = _ref.theme,
      withIcon = _ref.withIcon,
      withDelete = _ref.withDelete,
      size = _ref.size;
  var styles = {
    backgroundColor: alpha(theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main, 0.06),
    color: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main,
    padding: withDelete ? "".concat(theme.spacing(0.75), " ").concat(theme.spacing(1.5), " ").concat(theme.spacing(0.625), " ").concat(theme.spacing(1.5)) : withIcon ? "".concat(theme.spacing(1), " ").concat(theme.spacing(2), " ").concat(theme.spacing(1), " ").concat(theme.spacing(1.5)) : "".concat(theme.spacing(1), " ").concat(theme.spacing(2.5)),
    height: 'unset',
    borderRadius: pxToRem(34),
    '&.MuiChip-outlined': {
      borderColor: theme.colors.mirage
    },
    '&:hover': {
      backgroundColor: alpha(theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main, 0.16)
    },
    '.MuiChip-label': {
      paddingLeft: !withIcon ? '0px' : theme.spacing(1.25),
      paddingRight: !withDelete ? '0px' : theme.spacing(1.25)
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.mirage.main,
      color: theme.palette.mirage.main,
      opacity: 'unset',
      '&.MuiChip-outlined': {
        borderColor: theme.colors.mirage
      },
      svg: {
        '&.MuiChip-deleteIcon': {
          width: pxToRem(18),
          color: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main,
          margin: 0
        }
      }
    },
    svg: {
      '&.MuiChip-deleteIcon': {
        width: pxToRem(18),
        color: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main,
        margin: 0,
        '&:hover': {
          color: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main
        }
      }
    },
    '& .MuiChip-deleteIcon': {
      margin: 0
    }
  };

  switch (size) {
    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: withDelete ? "".concat(theme.spacing(1), " ").concat(theme.spacing(1), " ").concat(theme.spacing(1), " ").concat(theme.spacing(1.5)) : withIcon ? "".concat(theme.spacing(1), " ").concat(theme.spacing(1.5), " ").concat(theme.spacing(1), " ").concat(theme.spacing(1.1)) : "".concat(theme.spacing(1), " ").concat(theme.spacing(1.5))
        });
        break;
      }
  }

  return styles;
});
export { StyledChip };