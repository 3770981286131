import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { FETCH_SEND_SOCKET_EVENT_ERROR, FETCH_SEND_SOCKET_EVENT_REQUEST, FETCH_SEND_SOCKET_EVENT_SUCCESS, FETCH_SOCKET_CONNECT_FAILURE, FETCH_SOCKET_CONNECT_REQUEST, FETCH_SOCKET_CONNECT_SUCCESS, FETCH_SOCKET_DISCONNECT_FAILURE, FETCH_SOCKET_DISCONNECT_REQUEST, FETCH_SOCKET_DISCONNECT_SUCCESS, RECEIVE_SOCKET_EVENT } from './socket.action-types';
export var actions = {
  fetchSocketConnectRequest: function fetchSocketConnectRequest(payload) {
    var _ref;

    return _ref = {
      type: FETCH_SOCKET_CONNECT_REQUEST,
      payload: payload
    }, _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(FETCH_SOCKET_CONNECT_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, ERROR_ACTION, "".concat(FETCH_SOCKET_CONNECT_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _ref;
  },
  fetchSocketConnectSuccess: function fetchSocketConnectSuccess(payload) {
    return {
      type: FETCH_SOCKET_CONNECT_SUCCESS,
      payload: payload
    };
  },
  fetchSocketConnectFailure: function fetchSocketConnectFailure(payload) {
    return {
      type: FETCH_SOCKET_CONNECT_FAILURE,
      payload: payload
    };
  },
  receiveSocketEvent: function receiveSocketEvent(payload) {
    return {
      type: RECEIVE_SOCKET_EVENT,
      payload: payload
    };
  },
  fetchSendSocketEventRequest: function fetchSendSocketEventRequest(payload) {
    var _ref2;

    return _ref2 = {
      type: FETCH_SEND_SOCKET_EVENT_REQUEST,
      payload: payload
    }, _defineProperty(_ref2, WAIT_FOR_ACTION, "".concat(FETCH_SEND_SOCKET_EVENT_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref2, ERROR_ACTION, "".concat(FETCH_SEND_SOCKET_EVENT_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _ref2;
  },
  fetchSendSocketEventSuccess: function fetchSendSocketEventSuccess(payload) {
    return {
      type: FETCH_SEND_SOCKET_EVENT_SUCCESS,
      payload: payload
    };
  },
  fetchSendSocketEventError: function fetchSendSocketEventError(payload) {
    return {
      type: FETCH_SEND_SOCKET_EVENT_ERROR,
      payload: payload
    };
  },
  fetchSocketDisconnectRequest: function fetchSocketDisconnectRequest(payload) {
    var _ref3;

    return _ref3 = {
      type: FETCH_SOCKET_DISCONNECT_REQUEST,
      payload: payload
    }, _defineProperty(_ref3, WAIT_FOR_ACTION, "".concat(FETCH_SOCKET_DISCONNECT_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref3, ERROR_ACTION, "".concat(FETCH_SOCKET_DISCONNECT_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _ref3;
  },
  fetchSocketDisconnectSuccess: function fetchSocketDisconnectSuccess(payload) {
    return {
      type: FETCH_SOCKET_DISCONNECT_SUCCESS,
      payload: payload
    };
  },
  fetchSocketDisconnectFailure: function fetchSocketDisconnectFailure(payload) {
    return {
      type: FETCH_SOCKET_DISCONNECT_FAILURE,
      payload: payload
    };
  }
};