import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { alpha, Button as MuiButton, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledButton = styled(MuiButton)(function (_ref) {
  var theme = _ref.theme,
      color = _ref.color,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? 'text' : _ref$variant,
      size = _ref.size;

  var getColorConfig = function getColorConfig() {
    switch (true) {
      case color === 'primary':
        {
          return {
            regular: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            hover: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            focus: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            active: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            disabled: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            boxShadow: alpha(theme.palette.white.main, 0.08)
          };
        }

      case color === 'secondary':
        {
          return {
            regular: {
              color: theme.palette.silverChalice.main,
              borderColor: theme.palette.silverChalice.main
            },
            hover: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            focus: {
              color: theme.palette.silverChalice.main,
              borderColor: theme.palette.silverChalice.main
            },
            active: {
              color: theme.palette.silverChalice.main,
              borderColor: theme.palette.silverChalice.main
            },
            disabled: {
              color: theme.palette.silverChalice.main,
              borderColor: theme.palette.silverChalice.main
            },
            boxShadow: alpha(theme.palette.white.main, 0.08)
          };
        }

      case color === 'internationalOrange':
        {
          return {
            regular: {
              color: theme.palette.internationalOrange.main,
              borderColor: theme.palette.internationalOrange.main
            },
            hover: {
              color: theme.palette.internationalOrange.main,
              borderColor: theme.palette.internationalOrange.main
            },
            focus: {
              color: theme.palette.internationalOrange.main,
              borderColor: theme.palette.internationalOrange.main
            },
            active: {
              color: theme.palette.internationalOrange.main,
              borderColor: theme.palette.internationalOrange.main
            },
            disabled: {
              color: theme.palette.silverChalice.main,
              borderColor: theme.palette.silverChalice.main
            },
            boxShadow: alpha(theme.palette.white.main, 0.08)
          };
        }

      default:
        {
          return {
            regular: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            hover: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            focus: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            active: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            disabled: {
              color: theme.palette.white.main,
              borderColor: theme.palette.white.main
            },
            boxShadow: alpha(theme.palette.white.main, 0.08)
          };
        }
    }
  };

  var styles = {
    textTransform: 'none',
    boxShadow: 'none',
    minHeight: pxToRem(24),
    minWidth: pxToRem(24),
    fontFamily: "'PF Din Text Cond Pro', sans-serif"
  };

  switch (color) {
    case 'primary':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          backgroundColor: alpha(theme.palette.white.main, 0.08),
          color: theme.palette.mirage.main,
          ':hover': {
            backgroundColor: alpha(theme.palette.white.main, 0.16),
            boxShadow: 'none'
          },
          ':focus': {
            backgroundColor: alpha(theme.palette.white.main, 0.16),
            boxShadow: "0 0 0 ".concat(pxToRem(4), " ").concat(theme.palette.white.main)
          },
          ':active': {
            backgroundColor: theme.palette.white.main,
            boxShadow: 'none'
          },
          ':disabled': {
            backgroundColor: theme.palette.white.main,
            boxShadow: 'none',
            color: theme.palette.white.main
          }
        });
        break;
      }

    case 'secondary':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          backgroundColor: theme.palette.mirage.main,
          color: theme.palette.mirage.main,
          ':hover': {
            backgroundColor: theme.palette.mirage.main,
            boxShadow: 'none'
          },
          ':focus': {
            backgroundColor: theme.palette.mirage.main,
            boxShadow: "0 0 0 ".concat(pxToRem(4), " ").concat(theme.palette.mirage.main)
          },
          ':active': {
            backgroundColor: theme.palette.mirage.main,
            boxShadow: 'none'
          },
          ':disabled': {
            backgroundColor: theme.palette.mirage.main,
            boxShadow: 'none',
            color: theme.palette.mirage.main
          }
        });
        break;
      }

    case 'internationalOrange':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          backgroundColor: theme.palette.internationalOrange.main,
          color: theme.palette.white.main,
          ':hover': {
            backgroundColor: theme.palette.internationalOrange.main,
            boxShadow: 'none'
          },
          ':focus': {
            backgroundColor: theme.palette.internationalOrange.main,
            boxShadow: "0 0 0 ".concat(pxToRem(4), " ").concat(theme.palette.mirage.main)
          },
          ':active': {
            backgroundColor: theme.palette.internationalOrange.main,
            boxShadow: 'none'
          },
          ':disabled': {
            backgroundColor: alpha(theme.palette.silverChalice.main, 0.08),
            boxShadow: 'none',
            color: theme.palette.silverChalice.main
          }
        });
        break;
      }
  }

  var paletteConfig = getColorConfig();

  switch (variant) {
    case 'outlined':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          backgroundColor: 'transparent',
          borderWidth: pxToRem(2),
          borderStyle: 'solid',
          color: paletteConfig.regular.color,
          borderColor: paletteConfig.regular.borderColor,
          ':hover': {
            color: paletteConfig.hover.color,
            borderColor: paletteConfig.hover.borderColor,
            borderWidth: pxToRem(1),
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          ':focus': {
            color: paletteConfig.focus.color,
            borderColor: paletteConfig.focus.borderColor,
            borderWidth: pxToRem(1),
            boxShadow: "0 0 0 ".concat(pxToRem(4), " ").concat(paletteConfig.boxShadow),
            backgroundColor: 'transparent'
          },
          ':active': {
            color: paletteConfig.active.color,
            borderColor: paletteConfig.active.borderColor,
            borderWidth: pxToRem(1),
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          ':disabled': {
            color: paletteConfig.disabled.color,
            borderColor: paletteConfig.disabled.borderColor,
            borderWidth: pxToRem(1),
            boxShadow: 'none',
            backgroundColor: 'transparent'
          }
        });
        break;
      }

    case 'text':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          backgroundColor: 'transparent',
          color: paletteConfig.regular.color,
          ':hover': {
            color: paletteConfig.hover.color,
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          ':focus': {
            color: paletteConfig.focus.color,
            backgroundColor: 'transparent'
          },
          ':active': {
            color: paletteConfig.active.color,
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          ':disabled': {
            color: paletteConfig.disabled.color,
            boxShadow: 'none',
            backgroundColor: 'transparent'
          }
        });
        break;
      }
  }

  switch (size) {
    case 'large':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: theme.spacing(1.625, 2.75),
          height: pxToRem(56),
          borderRadius: pxToRem(12),
          fontWeight: 500,
          fontSize: pxToRem(18),
          lineHeight: pxToRem(20)
        });
        break;
      }

    case 'medium':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: theme.spacing(1.25, 2),
          borderRadius: pxToRem(8),
          fontWeight: 700,
          fontSize: pxToRem(14),
          lineHeight: pxToRem(18)
        });
        break;
      }

    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: theme.spacing(0.875, 1.5),
          borderRadius: pxToRem(6),
          fontWeight: 700,
          fontSize: pxToRem(13),
          lineHeight: pxToRem(18)
        });
        break;
      }

    case 'extraSmall':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: theme.spacing(0.625, 1),
          borderRadius: pxToRem(6),
          fontWeight: 700,
          fontSize: pxToRem(10),
          lineHeight: pxToRem(14)
        });
        break;
      }

    case 'slider':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: theme.spacing(1.625, 2.75),
          height: pxToRem(48),
          borderRadius: pxToRem(12),
          fontWeight: 500,
          fontSize: pxToRem(18),
          lineHeight: pxToRem(20)
        });
        break;
      }
  }

  return styles;
});
export { StyledButton };