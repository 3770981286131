var fileNameParser = function fileNameParser(disposition) {
  var filename = '';

  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  return filename;
};

var downloadBlobFile = function downloadBlobFile(blob, contentDisposition, defaultFilename) {
  var fileName = contentDisposition ? fileNameParser(contentDisposition) : defaultFilename || '';
  var url = window.URL.createObjectURL(blob);
  var link = document.createElement('a');
  link.setAttribute('download', fileName);
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export { downloadBlobFile, fileNameParser };