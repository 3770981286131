import { useEffect, useRef } from 'react';

function usePrevious(value) {
  var ref = useRef();
  useEffect(function () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ref.current = value;
  }, [value]);
  return ref.current;
}

export { usePrevious };