import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Box, styled } from '@mui/material';
var StyledPartnersBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {};
  return styles;
});
var StyledPartnersContainer = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.colors.codGray
  };
  return styles;
});
var StyledCopyContainer = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: theme.colors.copyCodGray,
    borderTop: "1px solid ".concat(theme.colors.internationalOrange)
  };
  return styles;
});
var FooterContainer = styled(Box)(function (_ref4) {
  var theme = _ref4.theme,
      $hideOnMobile = _ref4.$hideOnMobile;
  var styles = {};

  if ($hideOnMobile) {
    styles = _objectSpread(_objectSpread({}, styles), {}, _defineProperty({}, theme.breakpoints.down('desktop'), {
      display: 'none'
    }));
  }

  return styles;
});
export { FooterContainer, StyledCopyContainer, StyledPartnersBox, StyledPartnersContainer };