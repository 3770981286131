import { createSelector } from 'reselect';

var getForms = function getForms(state) {
  return state.forms;
};

export var getFormsSelector = createSelector(getForms, function (forms) {
  return forms;
});
export var getFormSelector = function getFormSelector(formId) {
  return createSelector(getFormsSelector, function (forms) {
    return forms.find(function (form) {
      return form.formId === formId;
    });
  });
};
export var getFormIdSelector = function getFormIdSelector(formId) {
  return createSelector([getFormSelector(formId)], function (form) {
    return form === null || form === void 0 ? void 0 : form.formId;
  });
};
export var getFormValuesSelector = function getFormValuesSelector(formId) {
  return createSelector([getFormSelector(formId)], function (form) {
    return form === null || form === void 0 ? void 0 : form.values;
  });
};