import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { CLOSE_POPUP, CLOSING_POPUP_WITH_ANSWER, HIDE_POPUP, INIT_CLOSE_POPUP, INIT_POPUP_CLOSING_WITH_ANSWER, POPUP_INIT, SET_POPUP } from './popups.action-types';
export var actions = {
  popupInitClosing: function popupInitClosing(payload) {
    return {
      type: INIT_CLOSE_POPUP,
      payload: payload
    };
  },
  popupClose: function popupClose(payload) {
    return {
      type: CLOSE_POPUP,
      payload: payload
    };
  },
  popupInit: function popupInit(payload) {
    var _ref;

    return _ref = {
      type: POPUP_INIT,
      payload: payload
    }, _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(POPUP_INIT, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, ERROR_ACTION, "".concat(POPUP_INIT, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _ref;
  },
  setPopup: function setPopup(payload) {
    return {
      type: SET_POPUP,
      payload: payload
    };
  },
  popupInitClosingWithAnswer: function popupInitClosingWithAnswer(payload) {
    return {
      type: INIT_POPUP_CLOSING_WITH_ANSWER,
      payload: payload
    };
  },
  closeWithAnswer: function closeWithAnswer(payload) {
    return {
      type: CLOSING_POPUP_WITH_ANSWER,
      payload: payload
    };
  },
  popupHide: function popupHide(payload) {
    return {
      type: HIDE_POPUP,
      payload: payload
    };
  }
};