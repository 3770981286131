import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { styled, Typography } from '@mui/material';
var StyledPopupTitleTypography = styled(Typography)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    color: theme.palette.white.main
  }, theme.breakpoints.down('lg'), {
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 500
  });

  return styles;
});
export { StyledPopupTitleTypography };