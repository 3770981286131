import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ConstantsUtil } from '@utils';
import { i18n } from '@localization';

var getPopupErrorMessage = function getPopupErrorMessage(error) {
  var _errorObject$violatio;

  var errorObject = _objectSpread({}, error);

  var errorCode = errorObject.status;

  if (error.message === ConstantsUtil.errors.NETWORK_ERROR || error.message === ConstantsUtil.errors.NETWORK_SETTING_ERROR) {
    errorCode = 2;
  }

  if (errorObject !== null && errorObject !== void 0 && (_errorObject$violatio = errorObject.violations) !== null && _errorObject$violatio !== void 0 && _errorObject$violatio.length) {
    errorObject.message = errorObject.violations.reduce(function (result, item, itemIndex) {
      if (!itemIndex) {
        return result + item.message;
      } else {
        return result + ", ".concat(item.message);
      }
    }, '');
  } else if (errorObject !== null && errorObject !== void 0 && errorObject.errorMessage) {
    errorObject.message = errorObject === null || errorObject === void 0 ? void 0 : errorObject.errorMessage;
  } else {
    if (i18n.exists("remote.error.".concat(errorCode))) {
      errorObject.name = i18n.t("remote.error.".concat(errorCode, ".name"));
      errorObject.message = i18n.t("remote.error.".concat(errorCode, ".message"));
    } else {
      errorObject.name = errorObject.name || i18n.t('remote.error.default.name');
      errorObject.message = errorObject.message || i18n.t('remote.error.default.message');
    }
  }

  return errorObject;
};

export { getPopupErrorMessage };