import { Routes } from '@enums';
import { i18n } from '@localization';
import merch from '@images/merch.svg';
export var menuItems = [{
  label: i18n.t('components.header.tickets'),
  route: Routes.Events
}, {
  label: i18n.t('components.header.membership'),
  route: Routes.Membership
}, // {
//   label: i18n.t('components.header.service'),
//   route: Routes.Service,
// },
{
  label: i18n.t('components.header.help'),
  route: Routes.Help
}, {
  label: i18n.t('components.header.contacts'),
  route: Routes.Contacts
}, {
  label: i18n.t('components.header.amur'),
  route: 'https://shop.hcamur.ru',
  isExternal: true,
  icon: merch
}, {
  label: i18n.t('components.header.basket'),
  route: Routes.Basket
}];
export var onAuthClick = function onAuthClick(user) {
  return function () {
    if (user) {
      var _process$env$profileU;

      document.location.replace("".concat((_process$env$profileU = process.env.profileUrl) !== null && _process$env$profileU !== void 0 ? _process$env$profileU : ''));
    } else {
      var _process$env$authUrl;

      document.location.replace("".concat((_process$env$authUrl = process.env.authUrl) !== null && _process$env$authUrl !== void 0 ? _process$env$authUrl : '', "/webapi/oauth/init"));
    }
  };
};
export var externalMobileMenuItems = [{
  label: '',
  route: Routes.Profile,
  clickHandler: undefined
}, {
  label: i18n.t('components.header.mainSite'),
  clickHandler: undefined
}, {
  label: 'Amur shop',
  clickHandler: undefined
}];