import { i18n } from '@localization';
var ConstantsUtil = {
  info: {},
  warnings: {},
  errors: {
    NETWORK_ERROR: i18n.t('remote.error.2.message'),
    NETWORK_SETTING_ERROR: i18n.t('remote.error.2.message'),
    APP_ALREADY_INIT_ERROR: i18n.t('common.errors.appAlreadyInitError'),
    TOKEN_MISSING: i18n.t('common.errors.tokenMissing'),
    INVALID_REQUEST: i18n.t('common.errors.invalid_request'),
    INVALID_REQUEST_MESSAGE: i18n.t('common.errors.invalid_request', {
      context: 'message'
    }),
    INVALID_TOKEN: i18n.t('common.errors.invalid_token'),
    INVALID_TOKEN_MESSAGE: i18n.t('common.errors.invalid_token', {
      context: 'message'
    }),
    INSUFFICIENT_SCOPE: i18n.t('common.errors.insufficient_scope'),
    INSUFFICIENT_SCOPE_MESSAGE: i18n.t('common.errors.insufficient_scope', {
      context: 'message'
    }),
    SERVER_ERROR: i18n.t('common.errors.server_error'),
    SERVER_ERROR_MESSAGE: i18n.t('common.errors.server_error', {
      context: 'message'
    }),
    INVALID_GRANT: i18n.t('common.errors.invalid_grant'),
    INVALID_GRANT_MESSAGE: i18n.t('common.errors.invalid_grant', {
      context: 'message'
    }),
    SOCKET_IS_DOWN: i18n.t('common.errors.socket_down')
  },
  actions: {
    ASYNC_SUCCESS: 'ASYNC_SUCCESS',
    ASYNC_FAILED: 'ASYNC_FAILED'
  },
  params: {
    PREV_PAGE: 'prevPage'
  }
};
export { ConstantsUtil };