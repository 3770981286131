import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Box, styled } from '@mui/material';
import { Typography } from '@elements';
import { pxToRem } from '@themes';
var Match = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      $empty = _ref.$empty;

  var styles = _defineProperty({
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: !$empty ? theme.palette.shark.main : '#2f3031',
    borderRadius: theme.typography.pxToRem(12),
    minHeight: theme.typography.pxToRem(200),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }, theme.breakpoints.down('lg'), {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100vw',
    minHeight: pxToRem(150),
    borderRadius: theme.typography.pxToRem(0)
  });

  if (!$empty) {
    styles = _objectSpread(_objectSpread({}, styles), {}, {
      '&:before': {
        content: '" "',
        display: 'block',
        position: 'absolute',
        backgroundColor: '#2f3031',
        width: '100%',
        height: '100%',
        top: 0,
        left: theme.typography.pxToRem(90),
        transform: 'rotate(-58deg)'
      },
      '&:after': {
        content: '" "',
        display: 'block',
        position: 'absolute',
        backgroundColor: '#2f3031',
        width: '100%',
        height: '100%',
        top: 0,
        left: "calc(50% + ".concat(theme.typography.pxToRem(80), ")")
      }
    });
  } else {
    styles = _objectSpread(_objectSpread({}, styles), {}, {
      '&:before': _defineProperty({
        content: '" "',
        display: 'block',
        position: 'absolute',
        backgroundColor: theme.palette.shark.main,
        width: theme.typography.pxToRem(500),
        height: theme.typography.pxToRem(200),
        top: 0,
        left: theme.typography.pxToRem(-332),
        transform: 'rotate(-81deg)'
      }, theme.breakpoints.down('desktop'), {
        left: theme.typography.pxToRem(-342)
      }),
      '&:after': _defineProperty({
        content: '" "',
        display: 'block',
        position: 'absolute',
        backgroundColor: theme.palette.shark.main,
        width: theme.typography.pxToRem(500),
        height: theme.typography.pxToRem(200),
        top: 0,
        right: theme.typography.pxToRem(-332),
        transform: 'rotate(-81deg)'
      }, theme.breakpoints.down('desktop'), {
        right: theme.typography.pxToRem(-336)
      })
    });
  }

  return styles;
});
var TeamLogo = styled('img')(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    height: theme.typography.pxToRem(80),
    maxWidth: theme.typography.pxToRem(200)
  };
  return styles;
});
var TeamName = styled(Typography)(function (_ref3) {
  var _;

  var theme = _ref3.theme;
  var styles = {
    '&&': (_ = {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      color: theme.palette.white.main,
      textTransform: 'uppercase'
    }, _defineProperty(_, theme.breakpoints.down('lg'), {
      fontSize: theme.typography.pxToRem(22)
    }), _defineProperty(_, theme.breakpoints.down('md'), {
      fontSize: theme.typography.pxToRem(16)
    }), _)
  };
  return styles;
});
var TeamBanner = styled('img')(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    maxHeight: theme.typography.pxToRem(200),
    maxWidth: '100%',
    zIndex: 1
  };
  return styles;
});
var TeamTitle = styled(Typography)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    '&&': _defineProperty({
      fontSize: theme.typography.pxToRem(48),
      fontWeight: 700,
      textTransform: 'uppercase',
      color: '#48494a',
      letterSpacing: theme.typography.pxToRem(18),
      textIndent: theme.typography.pxToRem(-18),
      maxHeight: theme.typography.pxToRem(112),
      wordBreak: 'break-word',
      maxWidth: '90%',
      textAlign: 'center'
    }, theme.breakpoints.down('md'), {
      fontSize: theme.typography.pxToRem(16)
    })
  };
  return styles;
});
var NumberText = styled(Typography)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    '&&': _defineProperty({
      fontSize: theme.typography.pxToRem(22),
      fontWeight: 400,
      color: theme.palette.white.main
    }, theme.breakpoints.down('md'), {
      fontSize: theme.typography.pxToRem(16)
    })
  };
  return styles;
});
var InfoText = styled(Typography)(function (_ref7) {
  var theme = _ref7.theme;
  var styles = {
    '&&': _defineProperty({
      fontSize: theme.typography.pxToRem(22),
      fontWeight: 400,
      color: theme.palette.secondarySilver.main
    }, theme.breakpoints.down('md'), {
      fontSize: theme.typography.pxToRem(16)
    })
  };
  return styles;
});
export { InfoText, Match, NumberText, TeamBanner, TeamLogo, TeamName, TeamTitle };