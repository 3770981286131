import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { alpha, Box, FormControl, FormHelperText, inputBaseClasses, styled, TextField } from '@mui/material';
var StyledFormControl = styled(FormControl)(function (_ref) {
  var fullWidth = _ref.fullWidth;
  var styles = {
    display: fullWidth && 'flex',
    maxWidth: '100%'
  };
  return styles;
});
var StyledInputBase = styled(TextField)(function (_ref2) {
  var _MuiInputBaseRoot;

  var theme = _ref2.theme,
      color = _ref2.color,
      size = _ref2.size;
  var styles = {
    '& .MuiFormLabel-root': {
      color: theme.palette.jumbo.main,
      '&.MuiInputLabel-shrink': {
        left: theme.typography.pxToRem(2)
      },
      '&.Mui-error': {
        color: theme.palette.guardsmanRed.main
      }
    },
    '& .MuiInputBase-root': (_MuiInputBaseRoot = {
      borderRadius: theme.typography.pxToRem(12),
      transition: theme.transitions.create(['border-color'])
    }, _defineProperty(_MuiInputBaseRoot, "& .".concat(inputBaseClasses.input), {
      boxSizing: 'border-box',
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 'normal',
      fontWeight: 400,
      color: theme.palette.mercury.main,
      position: 'relative',
      height: theme.typography.pxToRem(52),
      padding: theme.spacing(2)
    }), _defineProperty(_MuiInputBaseRoot, "& .".concat(inputBaseClasses.input, ":-webkit-autofill"), {
      '-webkit-text-fill-color': "".concat(theme.palette.mercury.main, " !important"),
      '-webkit-background-clip': 'text !important',
      'background-clip': 'text !important'
    }), _defineProperty(_MuiInputBaseRoot, "& .".concat(inputBaseClasses.input, ":-internal-autofill-selected"), {
      '-webkit-text-fill-color': "".concat(theme.palette.mercury.main, " !important"),
      '-webkit-background-clip': 'text !important',
      'background-clip': 'text !important'
    }), _defineProperty(_MuiInputBaseRoot, "& .".concat(inputBaseClasses.input, " ~ fieldset"), {
      backgroundColor: 'transparent',
      borderWidth: theme.typography.pxToRem(2),
      borderColor: alpha(theme.palette.white.main, 0.08),
      padding: theme.spacing(0, 1.25)
    }), _defineProperty(_MuiInputBaseRoot, "& .".concat(inputBaseClasses.input, ":hover ~ fieldset"), {
      borderColor: alpha(theme.palette.white.main, 0.03),
      backgroundColor: alpha(theme.palette.white.main, 0.03)
    }), _defineProperty(_MuiInputBaseRoot, "& .".concat(inputBaseClasses.input, ":focus ~ fieldset"), {
      backgroundColor: 'transparent',
      borderColor: alpha(theme.palette.white.main, 0.08),
      outline: 'none'
    }), _defineProperty(_MuiInputBaseRoot, "&.Mui-error .".concat(inputBaseClasses.input, " ~ fieldset"), {
      borderColor: theme.palette.guardsmanRed.main
    }), _defineProperty(_MuiInputBaseRoot, '& > input::placeholder', {
      color: theme.palette.silverChalice.main,
      opacity: 1
    }), _MuiInputBaseRoot)
  };

  switch (size) {
    case 'large':
      {
        styles = _objectSpread(_objectSpread({}, styles), _defineProperty({}, "& .".concat(inputBaseClasses.input), _objectSpread(_objectSpread({}, styles["& .".concat(inputBaseClasses.input)]), {}, {
          height: theme.typography.pxToRem(46),
          padding: theme.spacing(1.25, 2)
        })));
        break;
      }

    case 'medium':
      {
        styles = _objectSpread(_objectSpread({}, styles), _defineProperty({}, "& .".concat(inputBaseClasses.input), _objectSpread(_objectSpread({}, styles["& .".concat(inputBaseClasses.input)]), {}, {
          height: theme.typography.pxToRem(48),
          padding: theme.spacing(1.5, 1.375)
        })));
        break;
      }

    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), _defineProperty({}, "& .".concat(inputBaseClasses.input), _objectSpread(_objectSpread({}, styles["& .".concat(inputBaseClasses.input)]), {}, {
          height: theme.typography.pxToRem(38),
          padding: theme.spacing(1.25, 1.375),
          fontSize: theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(18)
        })));
        break;
      }
  }

  return styles;
});
var StyleFormHelperWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    zIndex: 1,
    marginTop: theme.typography.pxToRem(0)
  };
  return styles;
});
var StyledFormHelperErrorText = styled(FormHelperText)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.guardsmanRed.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding: theme.spacing(0, 0, 0, 2),
    margin: 'unset',
    maxWidth: '100%',
    '&.Mui-error': {
      color: theme.palette.guardsmanRed.main
    }
  };
  return styles;
});
var StyledFormHelperWarningText = styled(FormHelperText)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.mirage.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding: theme.spacing(0, 0, 0, 2),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
var StyledFormHelperSuccessText = styled(FormHelperText)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.mirage.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding: theme.spacing(0, 0, 0, 2),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
var StyledFormHelperText = styled(FormHelperText)(function (_ref7) {
  var theme = _ref7.theme;
  var styles = {
    display: 'block',
    padding: theme.spacing(0),
    margin: 'unset',
    maxWidth: '100%',
    color: theme.palette.mirage.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18)
  };
  return styles;
});
export { StyledFormControl, StyledFormHelperErrorText, StyledFormHelperSuccessText, StyledFormHelperText, StyledFormHelperWarningText, StyledInputBase, StyleFormHelperWrapper };